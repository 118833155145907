import { useState, useEffect } from 'react';

const useHideHeader = () => {
  const [hideElem, toggleHideElem] = useState(false);

  useEffect(() => {
    var lastScrollPos = Math.max(0, window.pageYOffset);
    window.onscroll = function() {
      var currentScrollPos = Math.max(0, window.pageYOffset);

      currentScrollPos >= lastScrollPos && toggleHideElem(true);
      currentScrollPos <= lastScrollPos && toggleHideElem(false);
      lastScrollPos = currentScrollPos;
    };
  });
  return hideElem;
};

export default useHideHeader;
