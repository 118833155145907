import { media } from '@glitz/core';
import React from 'react';
import { styled, theme } from '../../../../../Theme';
import KexLink from '../../../../KexLink/KexLink';
import ILink from '../../../Link.interface';

type PropTypes = {
  header?: string;
  links?: ILink[];
};

function FooterList({ header, links }: PropTypes) {
  return (
    <Container>
      {header && <Title>{header}</Title>}
      <Divider />
      <List>
        {links &&
          links.map((item: ILink, index: number) => (
            <ListItem key={item.href && item.href + index}>
              <Link href={item.href} fullPageReload={item.fullPageReload}>
                {item.text}
              </Link>
            </ListItem>
          ))}
      </List>
    </Container>
  );
}

const Container = styled.div({
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinMedium, {
    marginBottom: theme.none,
  }),
});
const Title = styled.h4({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.tiny,
  lineHeight: theme.lineHeight.relaxed,
  color: theme.black,
  margin: {
    xy: theme.none,
  },
  paddingBottom: theme.spacing(4),
  position: 'relative',
  display: 'block',
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.epsilon }),
});

const List = styled.ul({
  listStyle: 'none',
  padding: {
    xy: theme.none,
  },
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.epsilon }),
});

const ListItem = styled.li({
  padding: { y: theme.spacing(2) },
  letterSpacing: theme.letterSpacing.wide,
});

const Divider = styled.div({
  width: theme.spacing(16),
  height: theme.spacing(0.5),
  backgroundColor: theme.blueDark,
  marginBottom: theme.spacing(2),
});

const Link = styled(KexLink, {
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  textDecoration: 'none',
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.gamma }),
  color: theme.blueDark,
  ':hover': {
    textDecoration: 'underline',
  },
});

export default FooterList;
