import React, { useEffect, useRef } from 'react';
import { isInternalLink, isLinkFromSameCulture } from '../Common/Helpers';
import { useKexNavigate } from '../KexRouter/KexRouter';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';

type PropType = {
  content: string;
  className?: string;
};

function XhtmlComponent({
  content,
  className = 'body-text',
  ...rest
}: PropType) {
  const elmRef = useRef<HTMLDivElement>(null);
  const kexNavigate = useKexNavigate();
  const { siteRoute } = useAppSettingsData();
  useEffect(() => {
    const onClick = (e: any) => {
      const url = e.currentTarget.href;

      if (
        e.currentTarget.target !== '_blank' &&
        isInternalLink({ url }) &&
        isLinkFromSameCulture(url, siteRoute)
      ) {
        e.preventDefault();
        kexNavigate(url);
      }
    };

    const links: HTMLAnchorElement[] = elmRef.current
      ? [].slice.call(elmRef.current.querySelectorAll('a'))
      : [];
    links.forEach(link => {
      link.addEventListener('click', onClick);
    });
    return () =>
      links.forEach(link => link.removeEventListener('click', onClick));
  });

  return (
    <div
      className={className}
      {...rest}
      dangerouslySetInnerHTML={{ __html: content }}
      ref={elmRef}
    />
  );
}

export default XhtmlComponent;
