import UserCredentials from '../AccountPage/Models/UserCredentials.interface';
import ResetPasswordResponseMessageModel from '../ResetPasswordPage/Models/ResetPasswordResponseMessageModel.interface';
import { updateHeader } from '../Shared/Models/Headers/useQueryHeader';
import { updateUserState } from '../Shared/UserContextProvider/useQueryUserState';
import { canUseDOM } from '../Shared/Common/Helpers';

export async function signIn(
  userCredentials: UserCredentials,
  accountPageUrl: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  generalErrorLabel: string
) {
  setError(false);
  setValidationMessage('');

  const res = await fetch(`${accountPageUrl}SignIn`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    updateHeader(userCredentials.language);
    updateUserState();
    canUseDOM() && window.location.assign(data.redirectUrl);
  } else {
    setError(true);
    data.message !== ''
      ? setValidationMessage(data.message)
      : setValidationMessage(generalErrorLabel);
  }
  return data;
}

export async function SignOut(accountPageUrl: string, languageRoute: string) {
  const res = await fetch(`${accountPageUrl}SignOut`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    updateHeader(languageRoute);
    updateUserState();
    canUseDOM() && window.location.assign(data.redirectUrl);
  }
}

export const GenerateNewPasswordLink = async (
  resetPasswordUrl: string,
  email: string,
  onResult: (message: ResetPasswordResponseMessageModel) => void,
  onError: (errors: ResetPasswordResponseMessageModel[]) => void
) => {
  const escapedEmail = email.replace('+', '%2B').replace('-', '%2D');
  const url = `${resetPasswordUrl}/GenerateNewPasswordLink?email=${escapedEmail}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const data = await res.json();
  if (data.statusCode === 200) {
    onResult(data.message);
  } else {
    onError(data.errors);
  }
};

export const ResetPassword = async (
  resetPasswordUrl: string,
  userId: string,
  token: string,
  newPassword: string,
  onResult: (message: ResetPasswordResponseMessageModel) => void,
  onError: (errors: ResetPasswordResponseMessageModel[]) => void
) => {
  const url = `${resetPasswordUrl}ResetPassword?userId=${userId}&token=${token}&newPassword=${newPassword}`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  const data = await res.json();

  if (data.statusCode === 200) {
    await updateUserState();
    onResult(data.message);
  } else {
    onError(data.errors);
  }
};
