import { useEffect } from 'react';

function usePreventBackgroundScroll(enableScroll: boolean = false) {
  useEffect(() => {
    if (enableScroll) {
      return;
    } else {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'initial';
      };
    }
  }, [enableScroll]);
}

export default usePreventBackgroundScroll;
