import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { theme } from '../../Theme';
import { media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { GridSize } from '../../Enums/GridSize.enum';

type PropTypes = {
  children: React.ReactNode;
  type?: GridSize;
  noPadding?: boolean;
};

function Grid({ children, type, noPadding = false, ...rest }: PropTypes) {
  let css = {};

  switch (type) {
    case GridSize.Zero:
      css = { display: 'contents' };
      break;
    case GridSize.Three:
      css = Three;
      break;
    case GridSize.Four:
      css = Four;
      break;
    case GridSize.Eight:
      css = Eight;
      break;
    case GridSize.Usp:
      css = Usp;
      break;
    case GridSize.ContentContainer:
      css = Twelve;
      break;
    default:
      //Was the prevoius grid layout
      css = Four;
      break;
  }

  return (
    <StyledDiv css={css} {...rest} noPadding={noPadding}>
      {children}
    </StyledDiv>
  );
}

const Div = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  gridGap: theme.spacing(3),
  padding: { x: theme.spacing(4) },
});

const StyledDiv = React.memo(
  styled(
    ({
      compose,
      noPadding = false,
      ...restProps
    }: { noPadding?: boolean } & React.HTMLAttributes<HTMLOrSVGElement> &
      StyledProps) => (
      <Div
        css={compose({
          ...(noPadding && {
            padding: { x: theme.none },
          }),
        })}
        {...restProps}
      />
    )
  )
);

const Twelve: StyleOrStyleArray = {
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
  padding: { y: theme.none, x: theme.none },
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  gridColumnGap: theme.none,
  gridRowGap: theme.spacing(8),
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridRowGap: theme.spacing(4),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridRowGap: theme.spacing(8),
    gridColumnGap: theme.spacing(8),
  }),
};

const Four: StyleOrStyleArray = {
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: theme.spacing(3),
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridGap: theme.spacing(3),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.spacing(3),
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.spacing(8),
  }),
};

const Three: StyleOrStyleArray = {
  justifySelf: 'center',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  gridGap: theme.none,
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridGap: theme.spacing(3),
  }),
};

const Usp: StyleOrStyleArray = {
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridGap: theme.spacing(3),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.spacing(3),
  }),
};

const Eight: StyleOrStyleArray = {
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: 'repeat(8, minmax(0, 1fr))',
    gridGap: theme.spacing(8),
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.spacing(16),
  }),
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridGap: theme.spacing(4),
};

export default Grid;
