import React from 'react';
import { styled, StyledProps } from '@glitz/react';

type SearchIconType = StyledProps & {
  onClick?: () => void;
};

function SearchIcon({ compose, onClick }: SearchIconType) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      onClick={() => onClick && onClick()}
      fill={'white'}
    >
      <path d="M12.052554,2.06525082 C13.3860033,3.39870009 14.1217891,5.17411417 14.1217891,7.05937119 C14.1217891,8.56710402 13.6467063,10.008646 12.7652688,11.2114392 L12.685,11.315 L15.7133962,14.3425478 C16.0622272,14.6931651 16.0889871,15.241049 15.7924885,15.6233415 L15.711628,15.7152542 C15.5276298,15.8973749 15.2819562,15.9967657 15.0280215,15.9967657 C14.8164093,15.9967657 14.6105339,15.9277443 14.4402258,15.7982681 L14.3425477,15.7133963 L11.315,12.685 L11.2114391,12.7652689 C10.0888321,13.5879439 8.75824843,14.0566388 7.35998401,14.1154751 L7.05937112,14.1217891 C5.1741141,14.1217891 3.39870001,13.3860033 2.06531554,12.0526189 C-0.688427714,9.29988467 -0.688427714,4.81892928 2.06525074,2.06525082 C4.81923091,-0.688729342 9.29919866,-0.688104521 12.052554,2.06525082 Z M3.43523729,3.43508625 C1.43762621,5.43355138 1.43762621,8.68510755 3.43516174,10.6826431 C4.3439444,11.5914257 5.52986591,12.1160573 6.80352548,12.1780863 L7.059,12.184 L7.31522852,12.1780858 C8.50402115,12.1201869 9.61622019,11.6592734 10.4975358,10.8591182 L10.682799,10.6824872 C11.6519647,9.71417613 12.1843008,8.42959677 12.1843008,7.05937119 C12.1843008,5.68922059 11.6520112,4.40452998 10.6827186,3.43523737 C8.68394205,1.43731502 5.4315654,1.43705043 3.43523729,3.43508625 Z"></path>
    </styled.Svg>
  );
}

export default styled(SearchIcon);
