/**
 Colors
 */
export const primary = '#005EB8';
export const secondary = '#63B1BC';

/** Color typography */
export const secondaryText = '#1C1B21';
export const breadText = '#030303';
export const heading = primary;
export const loadMoreText = '#11579D';
export const grayishBlue = '#263238';
export const progressBarText = grayishBlue;

/** Color defaults */
export const black = '#030303';
export const white = '#FFFFFF';
export const red = '#FF0000'; // change/update when needed
export const darkGray = '#979797';
export const gray = '#B8B8B8';
export const lightGray = '#F2F2F2';
export const thinGray = '#E9E9E9';
export const transparent = 'transparent';
export const navigationItem = '#666666';
export const fairGray = '#D6D6D6';
export const textPrimary = '#202020';
export const veryLightGray = '#F8F8F8';
export const lightBlue = '#F0F8FF';
export const paperGray = '#9CA3AF';
export const brightRed = '#D40C0C';
export const blueGray = '#E0F1F5';
export const fairBlue = '#EEF9FB';
export const thinBlue = '#2A7A87';
export const aquaBlue = '#E0F1F5';
export const oceanBlue = '#CCDBEA';
export const brightBlue = '#005F9E';

/** Color components */
export const placeholder = '#AEAEAE'; // change/update when needed
export const sale = '#9C2929'; // change/update when needed
export const modal = '#A3A3A3'; // change/update when needed
export const hover = '#EDEDED'; // change/update when needed
export const backdrop = '#484848';
export const loadMoreBar = '#60B0BD';
export const scrollBarGray = '#666666';
export const bannerGray = '#F2F2F2';
export const carouselGray = '#C2C2C2';
export const blackAndWhite = '#DBDBDB';
export const blackAndWhiteNearBlack = '#202124';
export const blackAndWhiteDarkGrey = '#646464';
export const fairOrange = '#F57A13';

/** Color spot */
export const spotGreen = '#65AE6D';
export const spotOrange = '#E4732C';
export const spotBlue = '#29ACE0';
export const spotPink = '#D6247A';

/** Color brand */
export const blueDark = '#004C97';
export const mediumAqua = '#9BD3DD';
export const lightAqua = '#C8E9E9';
export const thinAqua = '#DCEBEC';
export const lightOrange = '#F35625';

/** TePe colors */
export const tepePink = '#F04E98';
export const tepeOrange = '#FF6A13';
export const tepeRed = '#D50032';
export const tepeYellow = '#F3D03E';
export const tepePurple = '#9873AC';
export const tepeBlue = '#00B5E2';
export const tepeGreen = '#43B02A';
export const tepeGrey = '#A2AAAD';

/** Validation */
export const successPrimary = '#97C594';
export const successText = '#144513';
export const errorPrimary = '#FEB2B2';
export const errorText = '#9A2626';
export const errorBorder = errorText;
export const warningPrimary = '#FAD291';
export const loading = '#4079B1';
export const disabled = loading;

/** Color buttons */
export const buttonPrimary = '#2E2E2E';
export const buttonLoading = '#ECECEC';
export const deactivatedButtonGrey = '#D1D5DB';
export const quantityButtonColor = '#DDDDDD';

// move colors constants from backend. Exmaple: theme.mapEpiColorConstants(backgroundColor),
export const mapEpiColorConstants = (color: string) =>
  (({
    None: 'none',
    White: white,
    AquaLight: lightAqua,
  } as { [key: string]: string })[color || 'None']);
