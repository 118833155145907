import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { useKexLoadingCircle } from '../../Loading/KexLoadingCircle/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../Configs/EnvConfig';
import FetchHeader from './FetchHeader';
import IHeader from './Header.interface';
import { useAppSettingsData } from '../../AppSettingsProvider/AppSettingsProvider';

let hasMounted = false;

export default function(initialData: IHeader): [IHeader] {
  const { languageRoute } = useAppSettingsData();
  const [data, setData] = useState<IHeader>(initialData);
  const kexLoadingCircleDispatch = useKexLoadingCircle();
  const queryData = useSWR<IHeader>(
    `/App/GetStandardHeader?culture=${languageRoute}`,
    FetchHeader,
    {
      initialData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch(!queryData.data ? 'add' : 'remove');
  }, [kexLoadingCircleDispatch, queryData.data]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateHeaderData(header: IHeader) {
  mutate('/App/GetStandardHeader', header, false);
}

export async function updateHeader(languageRoute: string) {
  const res = await fetch(`/App/GetStandardHeader?culture=${languageRoute}`);

  if (res.ok) {
    const { data: header } = await res.json();
    mutate('/App/GetStandardHeader', header, false);
  }
}
