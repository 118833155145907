import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function UserMenuIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M11,12 C11.5522847,12 12,12.4477153 12,13 C12,13.5522847 11.5522847,14 11,14 L1,14 C0.44771525,14 6.76353751e-17,13.5522847 0,13 C-6.76353751e-17,12.4477153 0.44771525,12 1,12 L11,12 Z M13,7 C13.5522847,7 14,7.44771525 14,8 C14,8.55228475 13.5522847,9 13,9 L1,9 C0.44771525,9 6.76353751e-17,8.55228475 0,8 C-6.76353751e-17,7.44771525 0.44771525,7 1,7 L13,7 Z M15,2 C15.5522847,2 16,2.44771525 16,3 C16,3.55228475 15.5522847,4 15,4 L1,4 C0.44771525,4 6.76353751e-17,3.55228475 0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 L15,2 Z"
        fillRule="nonzero"
      ></path>
    </styled.Svg>
  );
}

export default styled(UserMenuIcon);
