import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function CompactIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M14,13 C14.5522847,13 15,13.4477153 15,14 C15,14.5522847 14.5522847,15 14,15 L2,15 C1.44771525,15 1,14.5522847 1,14 C1,13.4477153 1.44771525,13 2,13 L14,13 Z M14,9 C14.5522847,9 15,9.44771525 15,10 C15,10.5522847 14.5522847,11 14,11 L2,11 C1.44771525,11 1,10.5522847 1,10 C1,9.44771525 1.44771525,9 2,9 L14,9 Z M14,5 C14.5522847,5 15,5.44771525 15,6 C15,6.55228475 14.5522847,7 14,7 L2,7 C1.44771525,7 1,6.55228475 1,6 C1,5.44771525 1.44771525,5 2,5 L14,5 Z M14,1 C14.5522847,1 15,1.44771525 15,2 C15,2.55228475 14.5522847,3 14,3 L2,3 C1.44771525,3 1,2.55228475 1,2 C1,1.44771525 1.44771525,1 2,1 L14,1 Z"
        id="Shape"
      ></path>
    </styled.Svg>
  );
}

export default styled(CompactIcon);
