import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { StyleOrStyleArray } from '@glitz/type';
import { theme } from '../../Theme';

type LoadingCircleType = StyledProps & {
  width?: string;
  isLoading?: boolean;
};

function LoadingCircle({ isLoading, compose }: LoadingCircleType) {
  return (
    <StyledSvg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <styled.Circle
        css={compose(isLoading ? LoadingStyles : {})}
        cx="50"
        cy="50"
        r="49"
        fill="none"
      />
    </StyledSvg>
  );
}

export default styled(LoadingCircle);

const LoadingStyles: StyleOrStyleArray = {
  transform: 'rotate(-90deg)',
  transformOrigin: '50%',
  animationDuration: theme.timings.oneHalf,
  animationTimingFunction: 'cubic-bezier(0.47, 0.01, 0.51, 1)',
  animationIterationCount: 'infinite',
  stroke: theme.darkGray,
  strokeWidth: '4px',
  strokeDashoffset: 'calc(-3.14 * 2 * 100%)',
  strokeDasharray: 'calc(3.14 * 2 * 100%)',
  fill: 'none',
  animationName: {
    '0%': {
      strokeDashoffset: 'calc(3.14 * 2 * 100%)',
    },
    '50%': {
      strokeDashoffset: '0%',
    },
    '100%': {
      strokeDashoffset: '-calc(3.14 * 2 * 100%)',
    },
  },
};

const StyledSvg = styled.svg({
  padding: {
    xy: theme.tiny,
  },

  overflow: 'visible',
});
