import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import Navigation from './Navigation';
import Breadcrumbs from '../Shared/Breadcrumb/Breadcrumb';
import HeaderLink from '../Shared/Models/Headers/HeaderLink.interface';
import { media } from '@glitz/core';
import useMedia from '../Shared/Hooks/useMedia';
import { DropDownButton } from '../Shared/Button/Button';
import Modal from '../Shared/Modal/Modal';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import AccountOverview from '../Shared/AccountOverview/AccountOverview';

type PropType = {
  links?: HeaderLink[];
  hideLeftMenu?: boolean;
  children: React.ReactNode;
  component?: React.ReactNode;
  isAccountPage?: boolean;
  dropDownText?: string;
  hideBreadcrumbs?: boolean;
};

function LeftNavigationLayout({
  links,
  hideLeftMenu = false,
  children,
  component,
  isAccountPage = false,
  dropDownText,
  hideBreadcrumbs = false,
}: PropType) {
  const {
    translations: { 'common/navigationLabel': navigationButtonText },
  } = useAppSettingsData();

  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  const toggleModalState = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <Modal overlay isNavigationModal toggle={toggleModalState}>
          {component ? (
            <>{component}</>
          ) : (
            <Navigation links={links} onNavigate={toggleModalState} />
          )}
        </Modal>
      )}
      {!hideBreadcrumbs && (
        <BreadcrumbWrapper>
          <Breadcrumbs />
        </BreadcrumbWrapper>
      )}
      <Container>
        <LayoutWrapper>
          {isMobile && (!!component || (links && links.length > 0)) && (
            <MobileContainer>
              <NavigationButton onClick={toggleModalState}>
                {dropDownText ? dropDownText : navigationButtonText}
              </NavigationButton>
            </MobileContainer>
          )}
          {!isMobile &&
            !hideLeftMenu &&
            (!!component || (links && links.length > 0)) && (
              <LeftPanel>
                {component ? <> {component} </> : <Navigation links={links} />}
                {isAccountPage && <AccountOverview />}
              </LeftPanel>
            )}
          <ChildWrapper>{children}</ChildWrapper>
        </LayoutWrapper>
      </Container>
    </>
  );
}

export default LeftNavigationLayout;

const LayoutWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  ...media(theme.mediaQuery.mediaMinLarge, {
    flexDirection: 'row',
  }),
});

const ChildWrapper = styled.div({ width: '100%' });

const BreadcrumbWrapper = styled.div({
  ...media(theme.mediaQuery.mediaMaxLarge, {
    margin: { x: theme.spacing(4) },
  }),
});

const LeftPanel = styled.div({
  width: '100%',
  maxWidth: theme.leftNavigationLayoutMaxWidth,
  marginRight: theme.spacing(8),
});

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
});

const MobileContainer = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.none,
    },
  }),
  marginBottom: theme.spacing(4),
});

const NavigationButton = styled(DropDownButton, { width: '100%' });
