import GetStandardFooterModel from '../../../../App/Models/GetStandardFooterModel.interface';
import IFooter from './Footer.interface';
import Fetcher from '../../../Common/Fetcher';

let abortController: AbortController = new AbortController();

function FetchFooter(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<IFooter, GetStandardFooterModel>(
    url,
    signal,
    (res, resolve, reject) => {
      if (res.success) {
        resolve(res.data);
      }
    }
  );
}

export default FetchFooter;
