import browser from 'browser-detect';
import { BrowserDetectInfo } from 'browser-detect/dist/types/browser-detect.interface';

type returnType = {
  isSupported: boolean;
  isLegacy: boolean;
  data: BrowserDetectInfo;
};

function legacyBrowserDetection(userAgent: string): returnType | null {
  const result = browser(userAgent);

  switch (result.name) {
    case 'ie':
      return {
        isSupported: (result.versionNumber ? result.versionNumber : 0) > 11,
        isLegacy: true,
        data: result,
      };
    case 'chrome':
      return {
        isSupported: (result.versionNumber ? result.versionNumber : 0) >= 69,
        isLegacy: (result.versionNumber ? result.versionNumber : 0) < 69,
        data: result,
      };
    case 'edge':
      return {
        isSupported: (result.versionNumber ? result.versionNumber : 0) >= 17,
        isLegacy: (result.versionNumber ? result.versionNumber : 0) < 17,
        data: result,
      };
    case 'firefox':
      return {
        isSupported: (result.versionNumber ? result.versionNumber : 0) > 62,
        isLegacy: (result.versionNumber ? result.versionNumber : 0) < 66,
        data: result,
      };
    case 'safari':
      return {
        isSupported: (result.versionNumber ? result.versionNumber : 0) > 11,
        isLegacy: (result.versionNumber ? result.versionNumber : 0) < 12.1,
        data: result,
      };
    case 'opera':
      return {
        isSupported: (result.versionNumber ? result.versionNumber : 0) >= 53,
        isLegacy: (result.versionNumber ? result.versionNumber : 0) < 53,
        data: result,
      };
    default:
      return {
        isSupported: false,
        isLegacy: false,
        data: result,
      };
  }
}
export default legacyBrowserDetection;
