import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function GridIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M5 8a1 1 0 011 1v4a1 1 0 01-1 1H1a1 1 0 01-1-1V9a1 1 0 011-1h4zm8 0a1 1 0 011 1v4a1 1 0 01-1 1H9a1 1 0 01-1-1V9a1 1 0 011-1h4zm-9 2H2v2h2v-2zm8 0h-2v2h2v-2zM5 0a1 1 0 011 1v4a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h4zm8 0a1 1 0 011 1v4a1 1 0 01-1 1H9a1 1 0 01-1-1V1a1 1 0 011-1h4zM4 2H2v2h2V2zm8 0h-2v2h2V2z"
        id="Shape"
      ></path>
    </styled.Svg>
  );
}

export default styled(GridIcon);
