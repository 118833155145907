import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { IS_PRODUCTION_ENV } from '../Configs/EnvConfig';
import FetchUserState from './FetchUserState';
import UserState from '../Models/UserState.interface';

let hasMounted = false;

export default function(initialData: UserState): [UserState] {
  const [data, setData] = useState<UserState>(initialData);
  const queryData = useSWR<UserState>('/App/GetUserState', FetchUserState, {
    initialData: hasMounted ? undefined : initialData,
    revalidateOnFocus: IS_PRODUCTION_ENV,
  });

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateUserState() {
  const res = await fetch('/App/GetUserState');
  if (res.ok) {
    const { userState } = await res.json();
    mutate('/App/GetUserState', userState, false);
  }
}
