import React from 'react';
import { styled, theme } from '../../Theme';
import Logo from '../../Shared/Icons/Logo';

function SimpleNavBar() {
  return (
    <NavBar>
      <LogoContainer>
        <Logo></Logo>
      </LogoContainer>
    </NavBar>
  );
}

export default SimpleNavBar;

const NavBar = styled.div({
  flexShrink: 0,
  height: `${theme.spacing(22)}px`,
  width: '100%',
  backgroundColor: theme.mediumAqua,
  boxShadow: '0 0 12px -4px rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: theme.zIndex.ModalTopBar,
});

const LogoContainer = styled.div({
  width: theme.spacing(22),
  height: theme.spacing(10),
});
