import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import LoadingPageType from '../Loading/LoadingPageType/LoadingPageType';
import useCurrentPage from '../Hooks/useCurrentPage';
import PageModelBase from '../Models/PageModelBase.interface';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';

import {
  pageViewDataLayer,
  userStatusDataLayer,
} from '../../utils/dataLayer.lib';
import { useUserStateData } from '../UserContextProvider/UserContextProvider';

/* not possible to use full dynamic imports, in server context it wont be able to locate chunks */
const loadablePages = {
  StartPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../StartPage/StartPage')
  ),
  StandardPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../StandardPage/StandardPage')
  ),
  OrderHistoryPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../OrderHistoryPage/OrderHistoryPage'
    )
  ),
  ImpersonatePage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../ImpersonatePage/ImpersonatePage')
  ),
  ProductPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../ProductPage/ProductPage')
  ),
  BlockPreviewPage: loadable(() =>
    import('../../BlockPreview/BlockPreviewPage')
  ),
  CategoryPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../CategoryPage/CategoryPage')
  ),
  CheckoutPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../CheckoutPage/CheckoutPage')
  ),
  OrderConfirmationPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../OrderConfirmation/OrderConfirmationPage'
    )
  ),
  SearchPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../SearchPage/SearchPage')
  ),
  OrderPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../OrderPage/OrderDetailPage')
  ),
  AccountPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../AccountPage/AccountPage')
  ),
  NotFoundPage: loadable(() => import('../../NotFoundPage/NotFoundPage')),
  UnauthorizedPage: loadable(() =>
    import('../../UnauthorizedPage/UnauthorizedPage')
  ),
  NewsPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../NewsPage/NewsPage')
  ),
  AssortmentPage: loadable(() =>
    import(/* webpackPrefetch: true */ '../../Assortment/AssortmentPage')
  ),
  RegistrationPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../RegistrationPage/RegistrationPage'
    )
  ),
  LanguageSelectorPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../LanguageSelectorPage/LanguageSelectorPage'
    )
  ),
  RetailerListingPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../RetailerListingPage/RetailerListingPage'
    )
  ),
  TepeShareDetailPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../TepeShareDetailPage/TepeShareDetailPage'
    )
  ),
  TepeShareListingPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../TepeShareListingPage/TepeShareListingPage'
    )
  ),
  ResetPasswordPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../ResetPasswordPage/ResetPasswordPage'
    )
  ),
  WebinarsDetailPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../WebinarsDetailPage/WebinarsDetailPage'
    )
  ),
  WebinarsListPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../WebinarsListPage/WebinarsListingPage'
    )
  ),
  TepeConnectRegistrationPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../TepeConnectRegistrationPage/TepeConnectRegistrationPage'
    )
  ),
  TepeConnectProfilePage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../TepeConnectEditProfilePage/TepeConnectEditProfilePage'
    )
  ),
  RecommendationToolPage: loadable(() =>
    import(
      /* webpackPrefetch: true */ '../../RecommendationToolPage/RecommendationToolPage'
    )
  ),
};

const KexPage = () => {
  const { pageType, pageId, pageTitle, canonicalLink } = useCurrentPage<
    PageModelBase
  >();
  const appSettings = useAppSettingsData();
  const { userId } = useUserStateData();

  const LoadablePage = (loadablePages as any)[pageType];

  useEffect(() => {
    pageViewDataLayer(
      pageTitle,
      canonicalLink,
      appSettings?.languageRoute,
      appSettings?.language
    );
  }, [pageId]);

  useEffect(() => {
    userStatusDataLayer(userId ?? '');
  }, [userId, pageId]);

  if (LoadablePage === undefined) return null;

  return <LoadablePage fallback={<LoadingPageType />} />;
};

export default KexPage;
