import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ProfileIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
    >
      <path
        d="M7,0 C9.58498038,0 11.6785714,2.11372173 11.6785714,4.71875 C11.6785714,5.94677778 11.2092845,7.09293464 10.4093598,7.95093099 L10.2329371,8.13030155 L10.082,8.268 L10.2163012,8.34055921 C12.3506926,9.52632204 13.7969088,11.8355126 13.9802514,14.4635191 L13.9950367,14.7462746 L14,15.03125 C14,15.5652105 13.5693513,16 13.0357143,16 C12.5020773,16 12.0714286,15.5652105 12.0714286,15.03125 C12.0714286,11.9307318 9.7877141,9.4375 7,9.4375 C4.2122859,9.4375 1.92857143,11.9307318 1.92857143,15.03125 C1.92857143,15.5652105 1.49792274,16 0.964285714,16 C0.430648691,16 0,15.5652105 0,15.03125 C0,12.2668243 1.39840417,9.78241428 3.55736575,8.4706168 L3.78339349,8.33913313 L3.917,8.267 L3.76763274,8.129713 C2.9707637,7.36296525 2.45793278,6.32684777 2.34502135,5.19412501 L2.32698949,4.94998302 L2.32142857,4.71875 C2.32142857,2.11372173 4.41501962,0 7,0 Z M7,1.9375 C5.48229367,1.9375 4.25,3.18164264 4.25,4.71875 C4.25,6.25585736 5.48229367,7.5 7,7.5 C8.51770633,7.5 9.75,6.25585736 9.75,4.71875 C9.75,3.18164264 8.51770633,1.9375 7,1.9375 Z"
        fillRule="nonzero"
      ></path>
    </styled.Svg>
  );
}

export default styled(ProfileIcon);
