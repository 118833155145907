import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ProfileIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <path
        d="M11,0 C17.0751322,0 22,4.92486775 22,11 C22,14.5382287 20.3294676,17.6862836 17.7340988,19.6984688 C17.6603192,19.7772594 17.5727966,19.843454 17.47566,19.8935742 C15.6586498,21.2181177 13.4206341,22 11,22 C8.57936591,22 6.34135022,21.2181177 4.52472941,19.8931295 C4.42720344,19.843454 4.33968084,19.7772594 4.26521563,19.6985177 C1.67053238,17.6862836 0,14.5382287 0,11 C0,4.92486775 4.92486775,0 11,0 Z M11,13.4375 C8.35691398,13.4375 6.16688863,15.6787422 5.94676337,18.5538475 C7.39174145,19.5220356 9.12989855,20.0869565 11,20.0869565 C12.8701015,20.0869565 14.6082585,19.5220356 16.0531291,18.553536 C15.8331114,15.6787422 13.643086,13.4375 11,13.4375 Z M11,1.91304348 C5.98141249,1.91304348 1.91304348,5.98141249 1.91304348,11 C1.91304348,13.3341705 2.79312416,15.4627871 4.23955967,17.0721241 C4.72726152,15.1248559 5.92489075,13.462518 7.55736575,12.4706168 L7.78339349,12.3391331 L7.917,12.267 L7.76763274,12.129713 C6.9707637,11.3629652 6.45793278,10.3268478 6.34502135,9.19412501 L6.32698949,8.94998302 L6.32142857,8.71875 C6.32142857,6.11372173 8.41501962,4 11,4 C13.5849804,4 15.6785714,6.11372173 15.6785714,8.71875 C15.6785714,9.94677778 15.2092845,11.0929346 14.4093598,11.950931 L14.2329371,12.1303016 L14.082,12.268 L14.2163012,12.3405592 C15.9640859,13.3115424 17.2504183,15.035834 17.7597388,17.0716318 C19.2068758,15.4627871 20.0869565,13.3341705 20.0869565,11 C20.0869565,5.98141249 16.0185875,1.91304348 11,1.91304348 Z M11,5.9375 C9.48229367,5.9375 8.25,7.18164264 8.25,8.71875 C8.25,10.2558574 9.48229367,11.5 11,11.5 C12.5177063,11.5 13.75,10.2558574 13.75,8.71875 C13.75,7.18164264 12.5177063,5.9375 11,5.9375 Z"
        fillRule="nonzero"
      ></path>
    </styled.Svg>
  );
}

export default styled(ProfileIcon);
