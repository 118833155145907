export enum GridSize {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Usp = 5,
  Eight = 8,
  ContentContainer = 12,
}
