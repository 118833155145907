import React, {
  useEffect,
  useCallback,
  useReducer,
  useRef,
  useMemo,
} from 'react';

import { useSaveScrollPosition, updateScrollPosition } from './ScrollHandling';
import useQueryPage from './useQueryPage';
import AppInitModel from '../Models/AppInitModel.interface';
import PageModelBase from '../Models/PageModelBase.interface';

type PropType = {
  appInitData: AppInitModel;
  initUrl: string;
  children: React.ReactNode;
};

export type KexNavigateEventStateType = PopStateEvent & {
  clicked?: true;
  id?: number;
};

export type KexNavigateType = (
  url: string,
  event?: PopStateEvent | undefined
) => Promise<PageModelBase>;

type kexNavigateCallbackRefType = {
  resolve: (data: any) => any;
  reject: (data: any) => any;
  eventState: KexNavigateEventStateType;
};

const KexRouterDispatchContext = React.createContext({});
const KexRouterCurrentPage = React.createContext({});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'updateUrl': {
      return { ...state, url: action.url };
    }
    default: {
      return state;
    }
  }
};
let hasMounted = false;

function KexRouter({ appInitData, initUrl, children }: PropType) {
  const [routerState, dispatchState] = useReducer(reducer, {
    url: initUrl,
  });
  const kexNavigateCallbackRef = useRef<kexNavigateCallbackRefType>();
  const [currentPageData, pageId, pageTitle, seoTitle] = useQueryPage(
    routerState.url,
    appInitData
  );

  const kexNavigate = useCallback((url: string, event?: PopStateEvent): Promise<
    PageModelBase
  > => {
    const { state } = event ? event : { state: { clicked: true } };
    return new Promise((resolve, reject) => {
      kexNavigateCallbackRef.current = {
        resolve,
        reject,
        eventState: state,
      };
      dispatchState({
        type: 'updateUrl',
        url: url,
      });
      // If hash, always scroll
      if (state?.clicked && window.location.hash?.length > 0) {
        if (window.location.hash.length > 0) {
          setTimeout(function() {
            var hash = window.location.hash;
            window.location.hash = '';
            window.location.hash = hash;
          }, 300);
        }
      }
    });
  }, []);

  useSaveScrollPosition(kexNavigate);

  useEffect(() => {
    document.title = `${seoTitle || pageTitle}`;
    // Couldn't find a typescript type for this, so had to use any
    const metaTitle: any | null = document.querySelector(
      "meta[property='og:title']"
    ) as any | null;
    if (metaTitle && seoTitle) {
      metaTitle.content = seoTitle;
    }
  }, [seoTitle, pageTitle]);

  /**
   * using useMemo instead of useEffect because we need to updateScrollPosition (change url)
   * before we render the new current page.
   */
  useMemo(() => {
    if (hasMounted) {
      if (kexNavigateCallbackRef.current) {
        if (currentPageData.responseUrl) {
          updateScrollPosition(
            kexNavigateCallbackRef.current.eventState,
            currentPageData.responseUrl,
            seoTitle || pageTitle
          );
          kexNavigateCallbackRef.current = undefined;
        }
      }
    } else {
      hasMounted = true;
    }
    /* we only want to trigger updateScrollPosition the first time we get data from
     * useQueryPage and it's safe to only use pageId as dependency.
     */
    // eslint-disable-next-line
  }, [pageId, currentPageData.responseUrl]);

  return (
    <KexRouterCurrentPage.Provider value={currentPageData}>
      <KexRouterDispatchContext.Provider value={kexNavigate}>
        {children}
      </KexRouterDispatchContext.Provider>
    </KexRouterCurrentPage.Provider>
  );
}

const useKexNavigate = () => {
  return React.useContext(KexRouterDispatchContext) as KexNavigateType;
};

function useKexRouterCurrentPage() {
  return React.useContext(KexRouterCurrentPage) as unknown;
}

export { KexRouter, useKexNavigate, useKexRouterCurrentPage };
