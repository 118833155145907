import { StyledProps } from '@glitz/react';
import React, { useState, useEffect } from 'react';
import { styled, theme } from '../Theme';
import KexLink from '../Shared/KexLink/KexLink';
import HeaderLink from '../Shared/Models/Headers/HeaderLink.interface';
import ChevronIcon from '../Shared/Icons/ChevronIcon';
import { pseudo } from '@glitz/core';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';

type PropType = {
  links?: HeaderLink[];
  onNavigate?: () => void;
};

type CurrentPageType = PageModelBase & {
  parentPageId?: number;
};

function Navigation({ links, onNavigate }: PropType) {
  const { pageId, parentPageId } = useCurrentPage<CurrentPageType>();
  const [openPanels, setOpenPanels] = useState<number[]>([]);
  const [activePanels, setActivePanels] = useState<number>(pageId);
  const kexNavigate = useKexNavigate();

  useEffect(() => {
    setActivePanels(pageId);
  }, [pageId]);

  const goToPage = (node: HeaderLink) => {
    setActivePanels(node.id);
    toggleNode(node.id, false, true);
    kexNavigate(node.href);
    onNavigate && onNavigate();
  };

  const toggleNode = (id: number, isOpen: boolean, isNavigation?: boolean) => {
    if (isOpen) return closeNode(id);

    isNavigation ? setOpenPanels([id]) : setOpenPanels([...openPanels, id]);
  };

  const closeNode = (id: number) => {
    const newList = openPanels.filter(item => {
      return item !== id;
    });

    setOpenPanels([...newList]);
  };

  const isNodeActive = (node: HeaderLink) => {
    return activePanels === node.id;
  };

  const hasActiveSubLinks = (obj: HeaderLink) => {
    if (activePanels === obj.id || (parentPageId && parentPageId === obj.id)) {
      return true;
    }

    for (let i in obj.subLinks) {
      if (obj.hasOwnProperty('subLinks')) {
        let hasActiveChildren = hasActiveSubLinks(obj.subLinks[i]) as boolean;
        if (hasActiveChildren) {
          return true;
        }
      }
    }
    return false;
  };

  const renderSubLinks = (node: HeaderLink, isTop: boolean) => {
    const hasSubLinks = Boolean(node.subLinks?.length);
    const isActive = isNodeActive(node);
    const isOpen = isActive || openPanels.includes(node.id);
    const Title = isTop ? TopNode : Node;
    const activeSubLinks = hasActiveSubLinks(node);

    return (
      <StyledItem isTop={isTop} key={node.id}>
        <Title>
          <StyledKexLink
            onClick={() => goToPage(node)}
            data-isactive={activeSubLinks || isActive}
          >
            {node.text}
          </StyledKexLink>
          {hasSubLinks && (
            <styled.Span onClick={() => toggleNode(node.id, isOpen)}>
              <StyledArrow
                css={{
                  transform:
                    activeSubLinks || isOpen ? 'rotate(-180deg)' : 'none',
                }}
                isTop={isTop}
              />
            </styled.Span>
          )}
        </Title>
        {hasSubLinks && (
          <SubNodes data-isopen={activeSubLinks || isOpen}>
            {node.subLinks.map(node => {
              return renderSubLinks(node, false);
            })}
          </SubNodes>
        )}
      </StyledItem>
    );
  };

  return (
    <>
      {links && (
        <NavigationWrapper>
          {links &&
            links.map(node => {
              return renderSubLinks(node, true);
            })}
        </NavigationWrapper>
      )}
    </>
  );
}

export default Navigation;

const NavigationWrapper = styled.div({
  width: '100%',
});

const TopNode = styled.h4({
  display: 'flex',
  justifyContent: 'space-between',
  font: {
    size: theme.gamma,
    weight: theme.fontWeight.bold,
  },
  color: theme.secondaryText,
  padding: { y: theme.spacing(4) },
});

const Node = styled.p({
  display: 'flex',
  justifyContent: 'space-between',
  padding: { y: theme.spacing(4) },
  color: theme.navigationItem,
});

const StyledKexLink = styled(KexLink, {
  ...pseudo([':nth-child(n)[data-isactive="true"]'], {
    position: 'relative',
    ...pseudo(':after', {
      content: '" "',
      display: 'block',
      position: 'absolute',
      left: 0,
      bottom: `-${theme.spacing(1)}px`,
      width: '100%',
      height: theme.tiny,
      backgroundColor: theme.black,
    }),
  }),
});

const Item = styled.div({
  padding: { left: theme.spacing(4) },
  ':last-of-type': {
    border: {
      xy: {
        width: theme.none,
      },
    },
  },
});

const SubNodes = styled.div({
  maxHeight: theme.none,
  overflow: 'hidden',
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    maxHeight: '100%',
  }),
});

const StyledItem = React.memo(
  styled(
    ({
      compose,
      isTop = false,
      children,
      ...restProps
    }: { isTop?: boolean; children: React.ReactNode } & React.HTMLAttributes<
      HTMLDivElement
    > &
      StyledProps) => (
      <Item
        css={compose({
          ...(isTop && {
            padding: { x: theme.spacing(4) },
            border: {
              bottom: {
                color: theme.thinGray,
                style: 'solid',
                width: theme.tiny,
              },
            },
          }),
        })}
        {...restProps}
      >
        {children}
      </Item>
    )
  )
);

const StyledArrow = React.memo(
  styled(
    ({
      compose,
      isTop = false,
      ...restProps
    }: { isTop?: boolean } & React.HTMLAttributes<HTMLOrSVGElement> &
      StyledProps) => (
      <ChevronIcon
        css={compose({
          height: theme.spacing(4),
          width: theme.spacing(4),
          transition: {
            duration: theme.timings.oneFifth,
            property: 'all',
          },
          fill: theme.darkGray,
          ...(isTop && {
            fill: theme.black,
          }),
          ':hover': {
            cursor: 'pointer',
          },
        })}
        {...restProps}
      />
    )
  )
);
