import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ArrowRightIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path d="M9.61289944,1.20970461 L9.70710678,1.29289322 L15.7071068,7.29289322 C16.0675907,7.65337718 16.0953203,8.22060824 15.7902954,8.61289944 L15.7071068,8.70710678 L9.70710678,14.7071068 C9.31658249,15.0976311 8.68341751,15.0976311 8.29289322,14.7071068 C7.93240926,14.3466228 7.90467972,13.7793918 8.20970461,13.3871006 L8.29289322,13.2928932 L12.585,9 L1,9 C0.44771525,9 0,8.55228475 0,8 C0,7.44771525 0.44771525,7 1,7 L12.585,7 L8.29289322,2.70710678 C7.93240926,2.34662282 7.90467972,1.77939176 8.20970461,1.38710056 L8.29289322,1.29289322 C8.65337718,0.932409257 9.22060824,0.904679722 9.61289944,1.20970461 Z"></path>
    </styled.Svg>
  );
}

export default styled(ArrowRightIcon);
