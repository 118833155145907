import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function ToothSentIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 190 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2142_29292)">
        <path
          d="M20.0103 124.492C-1.53679 97.7365 3.86598 51.364 30.8118 25.6775C63.2364 -5.23478 111.125 8.81042 118.044 10.8404C153.412 21.2115 186.82 52.369 189.999 90.4888C191.795 112.019 184.473 142.059 166.157 149.443C152.728 154.858 145.178 143.341 115.52 143.558C93.3455 143.723 90.7901 150.211 73.0981 149.226C69.5909 149.033 38.0062 146.838 20.0103 124.492Z"
          fill="#A4D8DE"
        />
        <path
          d="M27.541 94.3765L27.6528 94.9091L27.541 94.3765Z"
          fill="#223242"
        />
        <path
          opacity="0.52"
          d="M94.6413 163.803C114.77 163.803 131.087 162.808 131.087 161.581C131.087 160.354 114.77 159.359 94.6413 159.359C74.513 159.359 58.1958 160.354 58.1958 161.581C58.1958 162.808 74.513 163.803 94.6413 163.803Z"
          fill="#BBC5D4"
        />
        <path
          d="M137 51.713C133.504 44.7145 127.426 41.6345 121.528 40.0554C116.176 38.6212 110.819 40.024 106.075 43.5781C102.828 46.0155 99.2061 47.2498 95.5807 47.289C95.2688 47.289 94.0174 47.289 93.7056 47.289C90.0801 47.2459 86.4586 46.0115 83.2113 43.5781C78.4709 40.024 73.1107 38.6212 67.7583 40.0554C61.8601 41.6345 55.7826 44.7145 52.2858 51.713C46.1303 64.0368 47.3856 82.2697 53.5021 94.1076C54.7885 96.5998 54.9523 99.8365 54.8002 102.869C54.578 107.25 54.5156 111.361 54.6482 115.742C54.808 121.153 55.19 126.569 55.9853 131.882C57.3497 140.993 60.0318 150.111 65.2594 156.444C66.5615 158.023 68 159.398 69.5281 160.55C73.8124 163.775 78.5333 160.762 80.5019 154.994C81.7494 151.342 81.9599 147.231 82.225 143.226C82.8097 134.394 85.0864 126.149 92.4776 125.099C92.8908 125.04 93.3001 125.017 93.7095 125.029C94.0213 125.021 94.3332 125.052 94.649 125.084C94.9608 125.052 95.2766 125.021 95.5885 125.029C95.9978 125.017 96.411 125.04 96.8203 125.099C104.212 126.153 106.492 134.398 107.073 143.226C107.338 147.231 107.549 151.342 108.796 154.994C110.765 160.762 115.482 163.775 119.77 160.55C121.298 159.398 122.733 158.023 124.038 156.444C129.266 150.111 131.952 140.997 133.313 131.882C134.108 126.569 134.49 121.153 134.65 115.742C134.782 111.357 134.72 107.246 134.498 102.869C134.346 99.8404 134.509 96.5998 135.796 94.1076C141.908 82.2697 143.168 64.0368 137.012 51.713H137Z"
          fill="white"
        />
        <path
          d="M129.418 142.172C123.485 145.397 116.951 142.383 114.226 136.615C112.499 132.963 112.207 128.853 111.841 124.848C111.034 116.016 107.88 107.771 97.6429 106.721C97.0737 106.662 96.5046 106.639 95.9354 106.65C95.5027 106.643 95.07 106.674 94.6373 106.705C94.2045 106.674 93.7679 106.643 93.3391 106.65C92.7738 106.639 92.2047 106.662 91.6316 106.721C81.3985 107.775 78.2409 116.02 77.4339 124.848C77.0675 128.853 76.7751 132.963 75.0481 136.615C72.3232 142.383 65.7896 145.397 59.8563 142.172C59.178 141.804 58.5153 141.4 57.8643 140.985C59.4509 146.815 61.7821 152.242 65.2477 156.439C66.5498 158.018 67.9882 159.394 69.5164 160.546C73.8007 163.771 78.5176 160.757 80.4902 154.989C81.7377 151.337 81.9482 147.227 82.2133 143.222C82.798 134.39 85.0746 126.145 92.4659 125.095C92.8791 125.036 93.2884 125.013 93.6978 125.024C94.0096 125.017 94.3215 125.048 94.6373 125.079C94.9491 125.048 95.2649 125.017 95.5768 125.024C95.9861 125.013 96.3993 125.036 96.8086 125.095C104.2 126.149 106.48 134.394 107.061 143.222C107.326 147.227 107.537 151.337 108.784 154.989C110.753 160.757 115.47 163.771 119.758 160.546C121.286 159.394 122.721 158.018 124.027 156.439C127.492 152.242 129.824 146.815 131.41 140.985C130.759 141.4 130.1 141.8 129.418 142.172Z"
          fill="#E0F1F5"
        />
        <path
          d="M94.6412 58.2405C104.991 58.2405 113.774 50.4113 116.948 39.5295C113.162 39.6236 109.459 41.0382 106.075 43.5774C102.828 46.0147 99.2062 47.249 95.5807 47.2882C95.2689 47.2882 94.0175 47.2882 93.7056 47.2882C90.0802 47.2451 86.4586 46.0108 83.2113 43.5774C79.8276 41.0382 76.1242 39.6236 72.3389 39.5295C75.5121 50.4113 84.299 58.2405 94.6451 58.2405H94.6412Z"
          fill="#E0F1F5"
        />
        <path
          d="M160.216 48.6507L158.488 53.8158C158.461 53.8983 158.407 53.9671 158.326 54.0084L153.073 55.7553C152.777 55.8516 152.777 56.278 153.073 56.3811L158.299 58.1005C158.373 58.1418 158.427 58.2106 158.454 58.2931L160.136 63.4857C160.236 63.7952 160.667 63.802 160.775 63.4857L162.503 58.3206C162.53 58.2381 162.584 58.1693 162.665 58.128L167.743 56.4361C168.086 56.3192 168.086 55.8309 167.743 55.714C165.967 55.1294 162.685 54.0359 162.685 54.0359C162.611 53.9946 162.557 53.9258 162.53 53.8433L160.849 48.6507C160.748 48.3412 160.317 48.3344 160.216 48.6507Z"
          fill="white"
        />
        <path
          d="M126.127 51.9141L123.046 61.0263C122.998 61.1719 122.902 61.2932 122.758 61.366L113.396 64.4479C112.868 64.6178 112.868 65.3701 113.396 65.5521L122.71 68.5854C122.842 68.6582 122.938 68.7796 122.986 68.9252L125.983 78.0859C126.163 78.6319 126.93 78.6441 127.122 78.0859L130.203 68.9737C130.251 68.8281 130.347 68.7068 130.491 68.634L139.541 65.6491C140.153 65.4429 140.153 64.5814 139.541 64.3751C136.377 63.3438 130.526 61.4146 130.526 61.4146C130.395 61.3418 130.299 61.2204 130.251 61.0748L127.254 51.9141C127.074 51.3681 126.307 51.3559 126.127 51.9141Z"
          fill="#A4D8DE"
        />
        <path
          d="M148.522 39.1877L147.564 42.0583C147.551 42.1006 147.516 42.1429 147.475 42.1641L144.559 43.1374C144.394 43.1938 144.394 43.4266 144.559 43.483L147.461 44.4422C147.502 44.4634 147.53 44.5057 147.551 44.548L148.488 47.4327C148.543 47.609 148.784 47.609 148.84 47.4327L149.798 44.5621C149.811 44.5198 149.846 44.4775 149.887 44.4563L152.706 43.5183C152.899 43.4548 152.899 43.1797 152.706 43.1162C151.721 42.7918 149.901 42.1852 149.901 42.1852C149.86 42.1641 149.832 42.1218 149.811 42.0794L148.874 39.1947C148.819 39.0184 148.578 39.0184 148.522 39.1947V39.1877Z"
          fill="white"
        />
        <path
          d="M31.0843 102.993L29.8388 106.725C29.8208 106.78 29.776 106.835 29.7223 106.862L25.932 108.127C25.7169 108.201 25.7169 108.503 25.932 108.577L29.7043 109.824C29.7581 109.851 29.794 109.906 29.8208 109.961L31.0395 113.711C31.1111 113.941 31.4248 113.941 31.4964 113.711L32.7419 109.98C32.7599 109.925 32.8047 109.87 32.8584 109.842L36.5233 108.623C36.7741 108.54 36.7741 108.182 36.5233 108.1C35.2419 107.678 32.8764 106.89 32.8764 106.89C32.8226 106.862 32.7868 106.807 32.7599 106.752L31.5412 103.002C31.4696 102.773 31.156 102.773 31.0843 103.002V102.993Z"
          fill="white"
        />
        <circle cx="31.5" cy="31" r="24.5" fill="#EEF9FB" />
        <rect
          width="5.71061"
          height="27.1293"
          transform="matrix(0.705277 0.708931 -0.705277 0.708931 42.3164 20.5)"
          fill="#60B0BD"
        />
        <rect
          width="5.71061"
          height="15.4561"
          transform="matrix(-0.705277 0.708931 -0.705277 -0.708931 31.2373 39.7324)"
          fill="#60B0BD"
        />
      </g>
    </styled.Svg>
  );
}

export default styled(ToothSentIcon);
