import React, { useReducer } from 'react';
import { styled, theme } from '../../../Theme/';

type PropType = {
  children: React.ReactNode;
};

const KexLoadingDispatchContext = React.createContext({});

type ActionType = 'add' | 'remove';

const reducer = (state: { count: number }, action: ActionType) => {
  switch (action) {
    case 'add': {
      return { count: state.count + 1 };
    }
    case 'remove': {
      const count = state.count - 1;
      return { count: count < 0 ? 0 : count };
    }
    default: {
      return state;
    }
  }
};

const animateSvgOnLoading = {
  animationName: {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  animationDuration: theme.timings.twoFifths,
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
};

const animateCircleOnLoading = {
  animationName: {
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
      stroke: theme.primary,
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-35',
      stroke: theme.secondary,
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: '-124',
      stroke: theme.secondaryText,
    },
  },
  animationDuration: theme.timings.oneAndAHalf,
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
};
const divStyleOnLoading = {
  opacity: '1',
};

function KexLoadingCircle({ children }: PropType) {
  const [{ count }, dispatch] = useReducer(reducer, { count: 0 });
  const isLoading = count !== 0;

  return (
    <KexLoadingDispatchContext.Provider value={dispatch}>
      <OuterDiv css={isLoading ? divStyleOnLoading : {}}>
        <InnerDiv css={isLoading ? divStyleOnLoading : {}}>
          <Svg css={isLoading ? animateSvgOnLoading : {}} viewBox="0 0 50 50">
            <Circle
              css={isLoading ? animateCircleOnLoading : {}}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            />
          </Svg>
        </InnerDiv>
      </OuterDiv>
      {children}
    </KexLoadingDispatchContext.Provider>
  );
}

const useKexLoadingCircle: () => React.Dispatch<ActionType> = () => {
  return React.useContext(KexLoadingDispatchContext) as React.Dispatch<
    ActionType
  >;
};

export { KexLoadingCircle, useKexLoadingCircle };

const OuterDiv = styled.div({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  zIndex: theme.zIndex.pageLoader,
  overflow: 'hidden',
  top: 0,
  right: 0,
  opacity: '0',
  transition: {
    duration: theme.timings.threeTenths,
    property: 'opacity',
    timingFunction: theme.animation.timingFn,
  },
  pointerEvents: 'none',
});

const InnerDiv = styled.div({
  position: 'absolute',
  top: 'calc(40%)',
  right: '50%',
  transform: 'translate(50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  boxShadow: '0px 10px 10px -10px black',
  transition: {
    duration: theme.timings.threeTenths,
    delay: theme.timings.oneHalf,
    property: 'opacity',
    timingFunction: theme.animation.timingFn,
  },
  opacity: '0',
});

const Svg = styled.svg({
  position: 'absolute',
  top: 'calc(50% - 25px)',
  right: 'calc(50% - 25px)',
  width: '50px',
  height: '50px',
});

const Circle = styled.circle({
  stroke: theme.primary,
  strokeLinecap: 'round',
});
