import React from 'react';
import { styled, theme } from '../../Theme';
import IIconBlockModel from './Models/IconBlockModel.interface';
import ContentAreaItem from '../../Shared/Models/ContentAreaItem.interface';
import { applyEditModeAttr } from '../../Shared/Common/Helpers';
import KexLink from '../../Shared/KexLink/KexLink';

interface IconBlockModel extends ContentAreaItem {
  content: IIconBlockModel;
}

function IconBlock({
  content: { name, image, inEditMode, url },
}: IconBlockModel) {
  return (
    <StyledKexLink href={url}>
      <Image
        src={image ? image.href : ''}
        alt={name}
        {...applyEditModeAttr(inEditMode && 'Icon')}
      />
      {name && (
        <StyledHeading {...applyEditModeAttr(inEditMode && 'Heading')}>
          {name}
        </StyledHeading>
      )}
    </StyledKexLink>
  );
}

const StyledKexLink = styled(KexLink, {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.breadText,
});

const StyledHeading = styled.h4({
  letterSpacing: theme.letterSpacing.medium,
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bold,
  color: theme.blueDark,
  marginTop: theme.spacing(2),
});

const Image = styled.img({
  maxHeight: theme.spacing(16),
});

export default React.memo(IconBlock);
