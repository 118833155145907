/**
  Font sizes
  **/
export const tau = '10px';
export const alpha = '12px';
export const beta = '14px';
export const gamma = '16px';
export const delta = '18px';
export const epsilon = '20px';
export const zeta = '24px';
export const psi = '26px';
export const eta = '28px';
export const chi = '30px';
export const nu = '32px';
export const theta = '36px';
export const mu = '40px';
export const omikron = '44px';
export const iota = '48px';
export const rho = '56px';
export const kappa = '64px';
export const lambda = '128px';

/** 
  Line height
 **/
export const lineHeight = {
  none: 1,
  mediumTight: 1.2,
  tight: 1.25,
  moreTight: 1.33,
  mediumSnug: 1.35,
  snug: 1.375,
  moreSnug: 1.43,
  normal: 1.5,
  relaxed: 1.625,
  middleRelaxed: 1.71,
  moreRelaxed: 1.75,
  loose: 2,
  moreLoose: 2.125,
  extremelyLoose: 3.625,
};

/** 
  Letter spacing
 **/
export const letterSpacing = {
  normal: 0,
  narrow: 0.1,
  nearNormalWide: 0.45,
  normalWide: 0.6,
  nearWide: 0.7,
  wide: 0.9,
  moreWide: 1,
  nearMedium: 1.13,
  medium: 1.25,
  wider: 1.63,
  heading: 2,
  header: 3,
  largeHeader: 8,
  menu: 1.29,
};

/** 
  Font family
 **/

export const fontFamily = {
  main:
    'UniNeueRegular, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen-Sans, Ubuntu, Cantarell, sans-serif',
  heading:
    'UniNeueBold, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen-Sans, Ubuntu, Cantarell, sans-serif',
  fallback: 'Arial, Helvetica, sans-serif',
};

export const fontWeight = {
  lighter: 300,
  normal: 400,
  bolder: 500,
  bold: 700,
};
