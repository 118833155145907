/** 
  Spacings
**/
export const none = '0px';
export const tiny = '1px';

// More words we can use to define size - immense, wide, high, king-size

// helper to create spacings with base as 4. Kex-default = 4px. example: 40px padding = theme.spacing(10),
const BASE_SPACING = 4;
export const spacing = (multiplier = 1) => BASE_SPACING * multiplier;

/** 
  Widths
**/

export const screenMaxWidth = 1440;
export const contentMaxWidth = 1320;
export const videoMaxWidth = 1024;
export const maxWidthSmall = 960;
export const contentResultWidth = 864;
export const maxWidthMobile = 768;
export const blockMaxWidthMobile = 600;
export const mobileMenuMaxWidth = 310;
export const modalWidthUserAction = 472;
export const rowSectionContentMaxWidth = 976;
export const productInfoMaxWidth = 416;
export const arrowButtonWidth = 48;
export const paginationStepsMaxWidth = 355;
export const paginationButtonWidth = 108;
export const productInfoCardWidthDesktop = 262;
export const productInfoCardWidthMobile = 163;
export const tepeLogoFooterWidth = 128;
export const orderSummaryMaxWidth = 382;
export const shippingWrapperMaxWidth = 440;
export const tepeLogoHeaderWidth = 88;
export const orderConfirmationButtonWidth = 422;
export const modalStepBarWidthMobile = 24;
export const modalStepBarWidthDesktop = 60;
export const searchResultContentMaxWidth = 736;
export const userMenuMaxWidth = 304;
export const dropDownWrapper = 300;

/** 
  Heights
**/

export const heroMaxHeight = 420;
export const videoMaxHeight = 574;
export const standardBlockLargerImageHeight = 490;
export const standardBlockLargeImageHeight = 480;
export const standardBlockMediumImageHeight = 345;
export const thirdWidthBlockImageHeight = 312;
export const standardBlockSmallImageHeight = 250;
export const verySmallBlockImageHeight = 180;
export const mobileHeaderHeight = 50;
export const megaMenuMaxHeight = 630;
export const headerMaxHeight = 90;
export const topBarMaxHeight = 48;
export const scrollBarHeight = 5;
export const assortmentContentMaxHeight = 120;
export const footerHeightDesktop = 493;
export const footerHeightMobile = 700;
export const assortmentContentFadeHeight = 30;
export const tepeLogoFooterHeight = 58;
export const leftNavigationLayoutMaxWidth = 300;
export const dropDownHeight = 52;
export const tepeLogoHeaderHeight = 40;
export const impersonationBannerMaxHeight = 52;
export const quickSearchDesktopHeight = 158;
export const tepeLogoHeroHeight = 60;
export const extraMargin = spacing(8);
export const dropDownListWrapperMinHeight = 50;

/**
 * Image heights
 **/

export const thumbnailImageHeight = 128;
export const productImageMaxLength = 171;
export const productImageMaxHeight = 575;
export const productImageMaxHeightMedium = 720;
export const productImageMaxHeightLarge = 736;
export const imageBlockHeight = 442;

/**
 * Image Ratio padding
 * ratioDesktop --> 16:9
 * ratioMobile --> 4:3
 * ratioSquare --> 1:1
 * ratioCamera --> 3:2
 **/

export const ratioDesktop = '56.25%';
export const ratioMobile = '75%';
export const ratioSquare = '100%';
export const ratioCamera = '66.66%';
