import React from 'react';
import { styled, theme } from '../../Theme';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import { LinkType } from '../../Enums/LinkType.enum';
import { StyledProps } from '@glitz/react';
type ArrowType = StyledProps & { type: string; onClick?: () => void };
function LeftArrowButton({ type, onClick, compose }: ArrowType) {
  return (
    <Button onClick={onClick} css={compose()}>
      {type === LinkType.Left ? <ArrowLeft /> : <ArrowRight />}
    </Button>
  );
}

const Button = styled.button({
  backgroundColor: theme.mediumAqua,
  borderRadius: '50%',
  position: 'absolute',
  width: theme.arrowButtonWidth,
  height: theme.arrowButtonWidth,
  margin: { xy: 0 },
  padding: { xy: 0 },
  font: {
    size: theme.epsilon,
    weight: theme.fontWeight.lighter,
    stretch: 'normal',
    style: 'normal',
  },
  color: theme.blueDark,
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    outline: {
      width: 0,
    },
  },
});

const ArrowLeft = styled(ArrowRightIcon, {
  margin: { xy: theme.spacing(4) },
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  fill: theme.blueDark,
  transform: 'scale(-1,1)',
  position: 'absolute',
  top: 0,
});
const ArrowRight = styled(ArrowRightIcon, {
  margin: { xy: theme.spacing(4) },
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  fill: theme.blueDark,
  position: 'absolute',
  top: 0,
});

export default LeftArrowButton;
