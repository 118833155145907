import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function DownloadIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="m467.7,148.4c-6.9-55.1-60.3-97-126-97-13.6,0-26.9,1.8-39.7,5.5-23.6-28-61.5-44.9-102.7-44.9-70,0-126.9,48.9-126.9,108.9 0,2.9 0.2,5.9 0.4,8.9-37.5,19.5-60.9,54.6-60.9,93 0,60.1 56.9,108.9 126.9,108.9 11.3,0 20.4-9.1 20.4-20.3 0-11.2-9.1-20.3-20.4-20.3-47.4,0-86-30.6-86-68.3 0-26.4 19.7-50.8 50.2-61.9 10.1-3.7 15.5-14.5 12.6-24.8-1.5-5.3-2.3-10.3-2.3-15.2 0-37.6 38.6-68.2 86-68.2 32.8,0 63.2,15.3 77.5,39 5.2,8.6 16,12.1 25.3,8.3 12.4-5.2 25.7-7.8 39.5-7.8 46.4,0 85,30 85.9,66.8 0.2,6 3,11.7 7.8,15.4 16.6,13 25.8,30.2 25.8,48.4 0,37.6-38.6,68.3-86,68.3-15.5,0-30.9-3.4-44.3-9.9-10.1-4.9-22.4-0.7-27.3,9.4-4.9,10.1-0.7,22.3 9.5,27.2 19,9.2 40.5,14 62.1,14 70,0 126.9-48.9 126.9-108.9 0-27.7-12.4-54.4-34.3-74.5z" />
      <path d="m281.3,406.9l-24.3,24v-178.4c0-11.2-9.1-20.3-20.4-20.3-11.3,0-20.4,9.1-20.4,20.3v178.3l-24.3-24c-8-7.9-20.9-7.9-28.9,0.1-7.9,8-7.9,20.8 0.1,28.8l59.1,58.4c10.4,10.4 18.2,10.6 28.8,0l59.1-58.4c8-7.9 8-20.8 0.1-28.8-8-7.9-20.9-8-28.9-5.68434e-14z" />
    </styled.Svg>
  );
}

export default styled(DownloadIcon);
