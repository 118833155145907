import React from 'react';
import loadable from '@loadable/component';
import IHeader from './Header.interface';
import useQueryHeader from './useQueryHeader';
import useMedia from '../../Hooks/useMedia';
import { theme } from '../../../Theme';
import useCurrentPage from '../../Hooks/useCurrentPage';
import useHideHeader from '../../Hooks/useHideHeader';
import useSetHeaderHeight from '../../Hooks/useSetHeaderHeight';
import PageModelBase from '../PageModelBase.interface';
import { recToolPageType } from '../../../IndexServer';

const MobileHeader = loadable(() => import('./MobileHeader/MobileHeader'));
const DesktopHeader = loadable(() => import('./DesktopHeader/DesktopHeader'));

type PropType = {
  initData: IHeader;
  children?: React.ReactNode;
};

type HeaderContextType = IHeader & { headerHeight: number };

const HeaderContext = React.createContext({});

function HeaderProvider({ initData, children }: PropType) {
  const [headerData] = useQueryHeader(initData);
  const { pageType, inEditMode } = useCurrentPage<PageModelBase>();
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const hideElem = useHideHeader();
  const [setHeaderRef, headerHeight] = useSetHeaderHeight();

  return (
    <HeaderContext.Provider value={{ ...headerData, headerHeight }}>
      {/* episerver does not like loading both headers while in on-page-edit. When we do, it messes up some other CSS. */}
      {pageType !== recToolPageType &&
        (isDesktop || inEditMode ? (
          <DesktopHeader hideTopBar={hideElem} setInnerRef={setHeaderRef} />
        ) : (
          <MobileHeader setInnerRef={setHeaderRef} />
        ))}
      {children && children}
    </HeaderContext.Provider>
  );
}

const useHeaderData = () => {
  return React.useContext(HeaderContext) as HeaderContextType;
};

export { HeaderProvider, useHeaderData };
