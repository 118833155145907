import { media } from '@glitz/core';
import React from 'react';
import { styled, theme } from '../../../Theme';
import CloseIcon from '../../Icons/CloseIcon';

type ButtonType = {
  toggle: () => void;
  closeLabel: String;
};

export function TopClose({ toggle, closeLabel }: ButtonType) {
  return (
    <CloseButton onClick={() => toggle()}>
      <ButtonIcon />
      <span>{closeLabel}</span>
    </CloseButton>
  );
}

const CloseButton = styled.button({
  backgroundColor: theme.mediumAqua,
  borderRadius: '50%',
  width: theme.spacing(8),
  height: theme.spacing(8),
  position: 'absolute',
  margin: { xy: 0 },
  padding: { xy: 0 },
  top: theme.spacing(-4),
  right: theme.spacing(-4),
  font: {
    size: theme.epsilon,
    weight: theme.fontWeight.lighter,
    stretch: 'normal',
    style: 'normal',
  },
  letterSpacing: theme.letterSpacing.wide,
  color: theme.blueDark,
  display: 'flex',
  alignItems: 'center',
  ':focus': {
    outline: {
      width: 0,
    },
  },
  justifyItems: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: theme.spacing(12),
    height: theme.spacing(12),
    top: theme.spacing(-6),
    right: theme.spacing(-6),
  }),
});

const ButtonIcon = styled(CloseIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  margin: { xy: 'auto' },
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  fill: theme.blueDark,
});
