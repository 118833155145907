import React from 'react';
import { styled, StyledProps } from '@glitz/react';

interface ISmallArrowRightIcon {
  fill: string;
}

const SmallArrowRightIcon = ({
  compose,
  fill,
}: ISmallArrowRightIcon & StyledProps) => {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25V5.75ZM15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903806 9.98959 0.903806 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM1 7.25H15V5.75H1V7.25Z"
        fill={fill}
      />
    </styled.Svg>
  );
};

export default styled(SmallArrowRightIcon);
