import DataLayer from '../../types/dataLayer';
import IKexCart from '../Cart/Models/KexCart.interface';
import Order from '../Order/Models/Order.interface';
import TepeLineItem from '../KexVariation/Models/TepeLineItemModel.interface';

import { formatPriceToNumber } from './formatPriceToNumber';
import { removeSpacesFromString } from './removeSpacesFromString';

enum DataLayerEventsNames {
  CART = 'ga4.view_cart',
  LOGIN = 'login',
  PURCHASE = 'ga4.purchase',
  REMOVE_FROM_CART = 'ga4.remove_from_cart',
  ADD_TO_CART = 'ga4.add_to_cart',
  PRODUCT_VIEW = 'ga4.view_item',
  REGISTRATION = 'sign_up',
  PAGE_VIEW = 'pageViewEvent',
}

const DefaultBrand = 'TePe';

type CartDataLayer = (cart: IKexCart) => void;
type PurchaseDataLayer = (order: Order) => void;
type RemoveFromCartDataLayer = {
  (product: TepeLineItem): void;
  (product: TepeLineItem, totalPrice?: string, currency?: string): void;
  (product: TepeLineItem[], totalPrice?: string, currency?: string): void;
  (
    product: TepeLineItem,
    totalPrice: string,
    currency: string,
    q: number
  ): void;
};

type LoginDataLayer = (
  language: string,
  languageRoute: string,
  canonicalLink: string,
  id: string
) => void;
type ProductPageDataLayer = (
  isCartAddEvent: boolean,
  productPrice: string | number,
  loweredPrice: string,
  currentPageVariant: any,
  mainCategory: string,
  quantity: number,
  currency?: string,
  piecePrice?: string | number
) => void;
type RegistrationDataLayer = (
  siteRoute: string,
  languageRoute: string,
  customerNumber: number
) => void;

type PageViewDataLayer = (
  pageTitle: string,
  canonicalLink: string,
  languageRoute: string,
  language: string
) => void;

export const pushDataLayer = (dataLayerObject: DataLayer) =>
  void window?.dataLayer?.push(dataLayerObject);

const formatPrice = (price: string): number =>
  Number(removeSpacesFromString(formatPriceToNumber(price)[0]));

const clearDataLayer = () => pushDataLayer({ ecommerce: null });

export const userStatusDataLayer = (id: string) => {
  if (window?.dataLayer) {
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].user_id) {
        // If it's the same user id, no use changing it
        if (id === window.dataLayer[i].user_id) {
          return;
        }
      }
    }
  }

  // There was no user_id in the array
  if (id) {
    // This should replace, not add
    window?.dataLayer?.push({ user_id: id });
  }
};

export const cartDataLayer: CartDataLayer = cart => {
  pushDataLayer({
    event: DataLayerEventsNames.CART,
    ecommerce: {
      currency: cart?.currency,
      value: formatPrice(cart?.totalPrice),
      items: cart?.lineItems?.map(product => {
        const price = product?.price! * product?.skuQuantity!;
        return {
          item_name: product?.name || '',
          item_id: product?.articleNumber || '',
          item_brand: DefaultBrand,
          quantity: product?.sellablePackageQuantityInCart || 0,
          item_variant: product?.variantPackageInfo || '',
          item_category: product?.mainCategory,
          item_category2: product?.subCategory,
          price,
          discount: product?.discountedPrice
            ? formatPrice(product?.totalPrice) -
              formatPrice(product?.discountedPrice)
            : 0,
        };
      }),
    },
  });
};

export const loginDataLayer: LoginDataLayer = (
  language,
  languageRoute,
  canonicalLink,
  id
) => {
  pushDataLayer({
    event: DataLayerEventsNames.LOGIN,
    site_country: language,
    site_language: languageRoute,
    page_location: canonicalLink,
    user_id: id,
  });
};

export const purchaseDataLayer: PurchaseDataLayer = order => {
  pushDataLayer({
    event: DataLayerEventsNames.PURCHASE,
    ecommerce: {
      currency: order?.currency,
      value: formatPrice(order?.totalPrice),
      transaction_id: order?.orderNumber,
      shipping:
        formatPrice(order?.totalPriceForItemsWithFreightPrice) -
        formatPrice(order?.totalPriceForItems),
      tax: formatPrice(order?.totalVATPrice),
      items: order?.orderItems?.map(product => {
        const price = product?.price! * product?.skuQuantity!;
        return {
          item_name: product?.name || '',
          item_id: product?.articleNumber || '',
          item_brand: DefaultBrand,
          quantity: product?.sellablePackageQuantityInCart,
          item_category: product?.mainCategory,
          item_category2: product?.subCategory,
          item_variant: product?.variantPackageInfo,
          price,
          discount: product?.discountedPrice
            ? formatPrice(product?.totalPrice) -
              formatPrice(product?.discountedPrice)
            : 0,
        };
      }),
    },
  });
};

export const removeFromCartDataLayer: RemoveFromCartDataLayer = (
  product: any,
  totalPrice?: string,
  currency?: string,
  q?: number
) => {
  const isProductArray = Array.isArray(product);
  const splittedTotalPrice = isProductArray
    ? formatPriceToNumber(totalPrice)
    : formatPriceToNumber(product?.totalPrice);

  clearDataLayer();
  pushDataLayer({
    event: DataLayerEventsNames.REMOVE_FROM_CART,
    ecommerce: {
      currency: currency || product?.currency,
      value: q
        ? product?.price! * product?.skuQuantity! * q
        : +removeSpacesFromString(splittedTotalPrice[0]),
      items: isProductArray
        ? product.map((item: TepeLineItem) => {
            const price = item?.price! * item?.skuQuantity!;
            return {
              item_name: item?.name || '',
              item_id: item?.articleNumber || '',
              item_brand: DefaultBrand,
              quantity: item?.sellablePackageQuantityInCart,
              item_variant: item?.variantPackageInfo,
              item_category: item?.mainCategory,
              item_category2: item?.subCategory,
              price,
              discount: item?.discountedPrice
                ? formatPrice(item?.totalPrice) -
                  formatPrice(item?.discountedPrice)
                : 0,
            };
          })
        : [
            {
              item_name: product?.name || '',
              item_id: product?.articleNumber || '',
              item_brand: DefaultBrand,
              quantity: q || product?.sellablePackageQuantityInCart,
              item_variant: product?.variantPackageInfo,
              item_category: product?.mainCategory || '',
              item_category2: product?.subCategory,
              price: product?.price! * product?.skuQuantity!,
              discount: product?.discountedPrice
                ? formatPrice(product?.totalPrice) -
                  formatPrice(product?.discountedPrice)
                : 0,
            },
          ],
    },
  });
};

export const productPageDataLayer: ProductPageDataLayer = (
  isCartAddEvent = false,
  productPrice,
  loweredPrice,
  currentPageVariant,
  mainCategory,
  quantity,
  currency,
  piecePrice
) => {
  const priceWithDiscount = formatPrice(loweredPrice);
  const fullPrice =
    typeof productPrice === 'string' ? formatPrice(productPrice) : productPrice;
  const itemPrice =
    typeof piecePrice === 'number' ? piecePrice : formatPrice(piecePrice!);

  isCartAddEvent && clearDataLayer();
  pushDataLayer({
    event: isCartAddEvent
      ? DataLayerEventsNames.ADD_TO_CART
      : DataLayerEventsNames.PRODUCT_VIEW,
    ecommerce: {
      currency: currency || currentPageVariant?.currency,
      value: loweredPrice ? priceWithDiscount : fullPrice,
      items: [
        {
          item_name: currentPageVariant?.name || '',
          item_id: currentPageVariant?.articleNumber || '',
          price: itemPrice || 0,
          item_brand: DefaultBrand,
          discount:
            productPrice && loweredPrice ? fullPrice - priceWithDiscount : 0,
          item_category: mainCategory || currentPageVariant?.mainCategory,
          item_category2:
            currentPageVariant?.type ||
            currentPageVariant?.productGroup ||
            currentPageVariant?.subCategory,
          quantity,
          item_variant: currentPageVariant?.package
            ? `${currentPageVariant?.package?.description} ${currentPageVariant?.package?.amountInPackage} ${currentPageVariant?.package?.unit}`
            : currentPageVariant?.sellablePackageInfo || '',
        },
      ],
    },
  });
};

export const registrationDataLayer: RegistrationDataLayer = (
  siteRoute,
  languageRoute,
  customerNumber
) => {
  pushDataLayer({
    event: DataLayerEventsNames.REGISTRATION,
    site_country: siteRoute,
    site_language: languageRoute,
    user_id: customerNumber || '',
  });
};

export const pageViewDataLayer: PageViewDataLayer = (
  pageTitle,
  canonicalLink,
  languageRoute,
  language
) => {
  pushDataLayer({
    event: DataLayerEventsNames.PAGE_VIEW,
    page_title: pageTitle,
    page_location: canonicalLink,
    site_language: languageRoute,
    site_country: language,
  });
};
