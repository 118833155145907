import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function CartIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 21"
      css={compose()}
    >
      <path d="M8,0 C10.4446301,0 12.4776487,1.90487054 12.6991941,4.34619368 L12.7140056,4.5629619 L12.718,4.75 L14.5625,4.75 C15.2643258,4.75 15.8467964,5.25656818 15.9741344,5.92487059 L15.9934341,6.06058536 L16,6.2 L16,18.55 C16,19.3023212 15.4312217,19.9229374 14.7011743,19.9933479 L14.5625,20 L1.4375,20 C0.688885775,20 0.0760713369,19.423638 0.00656588124,18.6894146 L0,18.55 L0,6.2 C0,5.44767876 0.568778307,4.82706255 1.29882573,4.75665207 L1.4375,4.75 L3.282,4.75 L3.28612162,4.55722742 C3.39281253,2.17690095 5.23917712,0.237223115 7.56513458,0.0201967078 L7.7845597,0.00493643612 L8,0 Z M3.281,6.699 L1.937,6.699 L1.937,18.05 L14.062,18.05 L14.062,6.699 L12.718,6.699 L12.71875,9.525 C12.71875,10.0236485 12.3460611,10.4369243 11.8632526,10.4934195 L11.75,10.5 C11.3174839,10.5 10.946126,10.2134662 10.819173,9.77706684 L10.7907467,9.65443582 L10.78125,9.525 L10.781,6.699 L5.218,6.699 L5.21875,9.525 C5.21875,10.0236485 4.84606114,10.4369243 4.36325262,10.4934195 L4.25,10.5 C3.81748395,10.5 3.44612603,10.2134662 3.31917298,9.77706684 L3.29074672,9.65443582 L3.28125,9.525 L3.281,6.699 Z M8,1.95 C6.58727679,1.95 5.39786239,3.04350672 5.23768195,4.43807285 L5.2234522,4.60681516 L5.219,4.75 L10.78,4.75 L10.7761343,4.6036518 C10.6912895,3.18622531 9.56151676,2.04116873 8.16838937,1.95518424 L8,1.95 Z"></path>
    </styled.Svg>
  );
}

export default styled(CartIcon);
