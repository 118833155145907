import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function RetailerIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 17"
    >
      <path
        fillRule="nonzero"
        d="M14.09375,9.375 C14.524807,9.375 14.896648,9.65827347 15.0243235,10.0927205 L15.0529514,10.2148549 L15.0625,10.34375 L15.0625,14.09375 C15.0625,15.0264816 14.3898645,15.8114766 13.4708059,15.9720924 L13.3154208,15.9929987 L13.15625,16 L2.84375,16 C1.91101839,16 1.12602344,15.3273645 0.965407592,14.4083059 L0.944501302,14.2529208 L0.9375,14.09375 L0.9375,10.34375 C0.9375,9.80872415 1.37122415,9.375 1.90625,9.375 C2.33730702,9.375 2.70914801,9.65827347 2.83682346,10.0927205 L2.86545139,10.2148549 L2.875,10.34375 L2.875,14.062 L13.125,14.062 L13.125,10.34375 C13.125,9.88515641 13.4436545,9.50098857 13.8716245,9.40058538 L13.9807733,9.38151749 L14.09375,9.375 Z M14.09375,0 C15.0264816,0 15.8114766,0.672635497 15.9720924,1.59169413 L15.9929987,1.74707918 L16,1.90625 L16,4.953125 C16,7.42977693 13.9922769,9.4375 11.515625,9.4375 C10.2272101,9.4375 9.03548419,8.88918472 8.20199612,7.97534177 L8.04997906,7.79975731 L7.999,7.735 L7.94940669,7.79934521 C7.20931836,8.70148386 6.13678593,9.29033753 4.95027394,9.41343867 L4.71151654,9.43180369 L4.484375,9.4375 C2.16117518,9.4375 0.238672264,7.66671293 0.0201871457,5.37445709 L0.00473617972,5.15806849 L0,4.953125 L0,1.90625 C0,0.973518387 0.672635497,0.188523435 1.59169413,0.027907592 L1.74707918,0.0070013016 L1.90625,0 L14.09375,0 Z M7.031,1.937 L1.937,1.937 L1.9375,4.953125 C1.9375,6.30346121 2.98837724,7.4083519 4.31691727,7.49458261 L4.484375,7.5 C5.78071927,7.5 6.85869085,6.5285555 7.01193508,5.27687471 L7.0266212,5.11207956 L7.03125,4.953125 L7.031,1.937 Z M14.062,1.937 L8.968,1.937 L8.96875,4.953125 C8.96875,6.30346121 10.0196272,7.4083519 11.3481673,7.49458261 L11.515625,7.5 C12.8119693,7.5 13.8899408,6.5285555 14.0431851,5.27687471 L14.0578712,5.11207956 L14.0625,4.953125 L14.062,1.937 Z"
      />
    </styled.Svg>
  );
}
export default styled(RetailerIcon);
