// todo: inline runtime script file use /runtime-.+[.]js/
import path from 'path';
import fs from 'fs';

import './imports-server';
import React from 'react';
import { renderToString } from 'react-dom/server';
// currently we are not using redux, saving it for later and see if we are using it
// import { Provider } from 'react-redux';
// import { storeConfig } from './Shared/Configs/StoreConfig';
import { ChunkExtractor } from '@loadable/server';
import { GlitzServer } from '@glitz/core';
import { GlitzProvider } from '@glitz/react';
import { default as GlitzTransformers } from '@glitz/transformers';

import LegacyBrowserDetection from './Node/LegacyBrowserDetection';
import {
  loader,
  noscript,
  legacyBrowserMsg,
  legacyBrowserLogic,
  getGoogleTagManagerScript,
  // getGoogleTagManagerNoScript,
  getSplashLoaderStyle,
  teamtailorWidget,
  getRecommendationSpinnerStyle,
} from './Node/HtmlSnippets';
import rootStyles from './Node/RootStyles';

import App from './App';
import BaseModel from '../Features/Shared/Models/BaseModel.interface';
import { mediaQuerySorter } from './Theme/mediaQueries';

export const recToolPageType = 'RecommendationToolPage';

export default function(objectString: string) {
  const {
    appInitData,
    ssr = true,
    editMode = false,
    epiFooterScripts,
    epiHeaderScripts,
    userAgent = '',
  }: BaseModel = JSON.parse(objectString);
  const environment = appInitData.appSettings.environment || '';
  const googleTagManagerId = appInitData.appSettings.googleTagManagerId || '';
  const legacyBrowserMessage =
    appInitData.appSettings.legacyBrowserMessage || '';
  const noJavascriptMessage = appInitData.appSettings.noJavascriptMessage || '';
  const cookieBotId = appInitData.appSettings.cookiebotId;
  const languageRoute = appInitData.appSettings.languageRoute;
  const careerWidgetCompanyUrl = appInitData.appSettings.careerWidgetCompanyUrl;
  const browserInfo = LegacyBrowserDetection(userAgent);
  let polyfillsBundle = '';
  let iePolyfillsBundle = '';
  let bodyIeClass = '';
  if (browserInfo && browserInfo.data.name === 'ie') {
    iePolyfillsBundle = '<script src="/static/js/polyfills-ie.js"></script>';
    bodyIeClass = 'IE';
  }

  if (!browserInfo || browserInfo.isLegacy) {
    polyfillsBundle =
      '<script src="/dist/build-polyfills/polyfills.js"></script>';
  }

  /*
   * If we need to read build stats from backend
   *
   * const nodeStats = JSON.parse(
   *   fs.readFileSync(path.resolve(process.cwd(), 'loadable-stats.json'), 'utf8')
   * );
   * const nodeExtractor = new ChunkExtractor({ stats: nodeStats });
   */

  const clientStats = JSON.parse(
    fs.readFileSync(
      path.resolve(process.cwd(), `../build-client/loadable-stats.json`),
      'utf8'
    )
  );

  const webExtractor = new ChunkExtractor({ stats: clientStats });
  const glitz = new GlitzServer({
    transformer: GlitzTransformers(),
    mediaOrder: mediaQuerySorter,
  });

  // currently we are not using redux, saving it for later and see if we are using it
  // const store = storeConfig(appInitData);
  const jsx = webExtractor.collectChunks(
    // <Provider store={store}>
    <GlitzProvider glitz={glitz}>
      <App appInitData={appInitData} />
    </GlitzProvider>
    // </Provider>
  );

  const appHtml = renderToString(jsx);
  const GlitzStyleTags = glitz.getStyleMarkup();

  const appScriptTags = webExtractor
    .getScriptTags()
    .replace(/(src=")(\/)/gm, `$1/Dist/build-client$2`);
  const appLinkTags = webExtractor
    .getLinkTags()
    .replace(/(href=")(\/)/gm, `$1/Dist/build-client$2`);
  const appStyleTags = webExtractor
    .getStyleTags()
    .replace(/(href=")(\/)/gm, `$1/Dist/build-client$2`);

  return `<!DOCTYPE html>
  <html lang="${appInitData.appSettings.language}">
  <head>

  ${getSplashLoaderStyle()}
  ${getRecommendationSpinnerStyle()}
  <script>dataLayer = [];</script>
  <script data-cookieconsent="ignore">
  function gtag() {
      dataLayer.push(arguments);
  }
  gtag("consent", "default", {
      ad_personalization: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "granted",
      wait_for_update: 500,
  });
  gtag("set", "ads_data_redaction", true);
  gtag("set", "url_passthrough", true);
</script>
  ${googleTagManagerId && getGoogleTagManagerScript(googleTagManagerId)}

  ${googleTagManagerId &&
    `<script>
    dataLayer.push({
      "site": {
        "environment": "${environment}",
        "context": "${editMode ? 'epi-edit' : 'server'}"
      }
    });
  </script>`}
  ${
    !!cookieBotId
      ? `<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="${cookieBotId}" data-culture="${languageRoute}" data-blockingmode="auto" type="text/javascript"></script>`
      : ''
  }
  ${
    appInitData.currentPage && appInitData.currentPage.disableIndexability
      ? '<meta name="robots" content="noindex, nofollow">'
      : ''
  }
  <meta charset="utf-8">
  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="shortcut icon" href="/static/favicon/tepe_favicon.ico" />
  <link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/tepe_apple_touch_180.png">
  <link rel="apple-touch-icon" sizes="167x167" href="/static/favicon/tepe_apple_touch_167.png">
  <link rel="apple-touch-icon" sizes="152x152" href="/static/favicon/tepe_apple_touch_152.png">
  <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/tepe_favicon_32.png">
  <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/tepe_favicon_16.png">
  <link rel="manifest" href="/static/favicon/site.webmanifest">
  <link rel="mask-icon" href="/static/favicon/safari-pinned-tab.svg" color="#F7EDDA">
  <meta name="msapplication-TileColor" content="#F7EDDA">
  <meta name="theme-color" content="#F7EDDA">
  <title>${
    appInitData.currentPage && appInitData.currentPage.seoTitle
      ? appInitData.currentPage.seoTitle
      : appInitData.currentPage.pageTitle
      ? appInitData.currentPage.pageTitle
      : ''
  }</title>
  <meta name="description" content="${
    appInitData.currentPage && appInitData.currentPage.metaDescription
      ? appInitData.currentPage.metaDescription
      : ''
  }">
  <meta property="og:title" content="${
    appInitData.currentPage && appInitData.currentPage.pageTitle
      ? appInitData.currentPage.pageTitle
      : ''
  }" />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="${
    appInitData.currentPage && appInitData.currentPage.canonicalLink
      ? appInitData.currentPage.canonicalLink
      : ''
  }" />
  <meta property="og:description" content="${
    appInitData.currentPage && appInitData.currentPage.metaDescription
      ? appInitData.currentPage.metaDescription
      : ''
  }" />
  ${rootStyles}
  ${GlitzStyleTags}
  ${appStyleTags}
  ${epiHeaderScripts ? epiHeaderScripts : ''}
  ${appLinkTags}
  ${
    appInitData.currentPage && appInitData.currentPage.alternativeLinks
      ? Object.keys(appInitData.currentPage.alternativeLinks).reduce(
          (acc, key: string) => {
            return (
              acc +
              '<link rel="alternate" href="' +
              appInitData.currentPage.alternativeLinks[key] +
              '" hreflang="' +
              key +
              '" /> \n'
            );
          },
          ''
        )
      : ''
  }
  ${
    appInitData.currentPage && appInitData.currentPage.canonicalLink
      ? '<link rel="canonical" href="' +
        appInitData.currentPage.canonicalLink +
        '" />'
      : ''
  }
  <script>    
    function d365mktConfigureTracking() {
      return {Anonymize: true};
  }
  </script>
  </head>
  <body class="${bodyIeClass}">
    ${
      /*getGoogleTagManagerNoScript({
      googleTagManagerId: googleTagManagerId,
    })*/ ''
    }
    ${ssr ? '' : loader}
    ${noscript(noJavascriptMessage)}
    ${
      !browserInfo || !browserInfo.isSupported
        ? legacyBrowserMsg(legacyBrowserMessage)
        : ''
    }
    <div id="root">${ssr ? appHtml : ''}</div>
    <script>window.__APP_INIT_DATA__ = ${JSON.stringify(appInitData)}</script>
    ${iePolyfillsBundle}
    ${polyfillsBundle}
    ${
      // removing async attribute on script tags for client render. Without it, it can cause
      // call of undefined error on page load.
      ssr ? appScriptTags : appScriptTags.replace(/\sasync\s/gm, ' ')
    }

    ${!browserInfo || !browserInfo.isSupported ? legacyBrowserLogic : ''}

    ${epiFooterScripts ? epiFooterScripts : ''}

    ${
      appInitData.currentPage.pageType !== recToolPageType &&
      !!careerWidgetCompanyUrl
        ? teamtailorWidget(careerWidgetCompanyUrl)
        : ''
    }

    <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"></script>
    </body>
  </html>`;
}
