import './imports';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { GlitzClient } from '@glitz/core';
import { GlitzProvider } from '@glitz/react';
import { default as GlitzTransformers } from '@glitz/transformers';
import { mediaQuerySorter } from './Theme/mediaQueries';
// currently we are not using redux, saving it for later and see if we are using it
// import { Provider } from 'react-redux';
// import { storeConfig } from './Shared/Configs/StoreConfig';
// const store = storeConfig(state);
import AppInitModel from './Shared/Models/AppInitModel.interface';

import App from './App';
import { mutate } from 'swr';

const state = (window as any).__APP_INIT_DATA__ as AppInitModel;

const rootEle: any = document.getElementById('root');
const glitz = new GlitzClient({
  transformer: GlitzTransformers(),
  mediaOrder: mediaQuerySorter,
});

if (window.history.state === null) {
  window.history.replaceState(
    { id: new Date().valueOf() },
    document.title,
    window.location.href
  );
}

if (rootEle.children[0]) {
  loadableReady(() => {
    hydrate(
      // currently we are not using redux, saving it for later and see if we are using it
      // <Provider store={store}>
      <GlitzProvider glitz={glitz}>
        <App appInitData={state} />
      </GlitzProvider>,
      // </Provider>
      rootEle
    );
  });
} else {
  render(
    // currently we are not using redux, saving it for later and see if we are using it
    // <Provider store={store}>
    <GlitzProvider glitz={glitz}>
      <App appInitData={state} />
    </GlitzProvider>,
    // </Provider>
    rootEle,
    () => {
      const splash: any = document.getElementById('splash');
      splash != null &&
        splash.firstElementChild.setAttribute(
          'class',
          splash.firstElementChild
            .getAttribute('class')
            .replace('splash-pulse', '')
        );
      splash != null && splash.classList.add('splash-remove');
      setTimeout(() => {
        splash && document.body.removeChild(splash);
      }, 700);
    }
  );
}

// Listen to the "beta/epiReady" event to update the "context" property.
window.addEventListener('load', () => {
  // Expect "epi" to be there after the "load" event. If it's not then we're
  // not in any editing context.
  let updateTimer: number;
  const epi = (window as any).epi;
  if (!epi) {
    return;
  }

  function setContext() {
    epi.publish('beta/domUpdated');
    // Subscribe to *all* property updates in on-page edit mode
    epi.subscribe('beta/contentSaved', function(propertyDetails: any) {
      // Ignore update if it wasn't successful, for example if there were validation errors
      if (!propertyDetails.successful) {
        return;
      }
      // fetch new complete data and update whole state.
      clearTimeout(updateTimer);
      updateTimer = window.setTimeout(() => {
        mutate('epiedit');
      }, 500);
    });
  }

  // Check for beta and that ready is an actual true value (not just truthy).
  if (epi.beta && epi.beta.ready === true) {
    // "beta/epiReady" already fired.
    setContext();

    // The subscribe method won't be available in View mode.
  } else if (epi.subscribe) {
    epi.subscribe('beta/epiReady', () => {
      setContext();
    });
  }
});
