import React from 'react';
import { styled, theme } from '../../../../../Theme';
import XhtmlComponent from '../../../../XhtmlComponent/XhtmlComponent';
import { Logo } from '../../../../Icons';
import { media } from '@glitz/core';

type PropTypes = {
  text?: string;
};

function CompanyInfo({ text }: PropTypes) {
  return (
    <Wrapper>
      <StyledLogo />
      <Text>
        <XhtmlComponent content={text || ''} />
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  color: theme.black,
});

const StyledLogo = styled(Logo, {
  height: theme.tepeLogoFooterHeight,
  width: theme.tepeLogoFooterWidth,
  marginBottom: theme.spacing(2),
});

const Text = styled.div({
  fontSize: theme.beta,
  ...media(theme.mediaQuery.mediaMinMedium, { fontSize: theme.gamma }),
  lineHeight: theme.lineHeight.normal,
});

export default CompanyInfo;
