import React from 'react';
import { styled, theme } from '../../Theme';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import ErrorBlock from '../../Blocks/ErrorBlock/ErrorBlock';
import ErrorImage from '../Images/500ErrorImage';
import SimpleNavBar from '../../Shared/NavBar/SimpleNavBar';

function KexReporterModal() {
  const {
    translations: {
      'error/errorHeader': errorHeader,
      'error/errorSubHeader': errorSubHeader,
      'error/errorBody': errorBody,
      'error/errorGoBack': errorGoBack,
      'error/errorGoToStartPage': errorGoToStartPage,
    },
  } = useAppSettingsData();

  const content = {
    bodyText: `<p><strong>${errorSubHeader}</strong></p><p>${errorBody}</p>`,
    image: {
      title: '',
      src: ErrorImage(),
      mobileSrc: '',
      alt: '',
      name: '',
      url: '',
      parentCode: '',
    },
    links: [
      {
        fullPageReload: true,
        text: errorGoToStartPage,
        title: errorGoToStartPage,
        target: window.location.origin.toString(),
        href: window.location.origin.toString(),
        isMedia: true,
        id: errorGoToStartPage,
      },
      {
        fullPageReload: true,
        text: errorGoBack,
        title: errorGoBack,
        target: window.location.toString(),
        href: window.location.toString(),
        isMedia: true,
        id: errorGoBack,
      },
    ],
    title: errorHeader,
    inEditMode: false,
    extraMargin: false,
  };

  return (
    <Root>
      <SimpleNavBar></SimpleNavBar>
      <ErrorBlock content={content}></ErrorBlock>
    </Root>
  );
}

export default KexReporterModal;

const Root = styled.div({
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.errorModal,
  backgroundColor: theme.lightAqua,
});
