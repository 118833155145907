import React from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import KexLink from '../../Shared/KexLink/KexLink';
import { styled, theme } from '../../../Features/Theme';
import { media, pseudo } from '@glitz/core';

type PropTypes = {
  query?: string;
};

function Breadcrumbs(props: PropTypes) {
  const { breadcrumb } = useCurrentPage<PageModelBase>();

  const {
    translations: { 'common/home': home },
    siteRoute,
  } = useAppSettingsData();

  const breadcrumbsList = !props.query
    ? breadcrumb
    : [...breadcrumb, { text: props.query, href: '' }];

  return (
    <BreadcrumbDiv>
      <BreadcrumbContainer>
        <BreadcrumbLink href={`/${siteRoute}`}>
          {home}
          <Seperator> / </Seperator>
        </BreadcrumbLink>
        {breadcrumbsList.map((link, index) => (
          <BreadcrumbLink key={index} href={link.href}>
            {index === breadcrumbsList.length - 1 && props.query
              ? props.query
              : link.text}
            {index !== breadcrumbsList.length - 1 && <Seperator> / </Seperator>}
          </BreadcrumbLink>
        ))}
      </BreadcrumbContainer>
    </BreadcrumbDiv>
  );
}

const BreadcrumbDiv = styled.div({
  alignItems: 'center',
  display: 'flex',
  WebkitOverflowScrolling: 'touch',
  margin: { xy: theme.spacing(4) },
  width: '100%',
  padding: { y: theme.spacing(2) },
  overflowX: 'auto',
  maxWidth: theme.contentMaxWidth,
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { right: 'auto', left: theme.spacing(4) },
  }),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    margin: { x: theme.none },
  }),
});

const BreadcrumbContainer = styled.div({
  display: 'flex',
});

const BreadcrumbLink = styled(KexLink, {
  fontSize: theme.alpha,
  display: 'flex',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  lineHeight: 1.33,
  letterSpacing: 0.45,
  color: theme.blueDark,
  ...pseudo(':last-of-type', {
    pointerEvents: 'none',
    color: 'black',
    fontWeight: 'normal',
  }),
});

const Seperator = styled.span({
  padding: { left: theme.spacing(1), right: theme.spacing(1) },
});

export default Breadcrumbs;
