import React from 'react';
import { useKexNavigate } from '../KexRouter/KexRouter';
import {
  isInternalLink,
  isEmailLink,
  hasFileExtension,
} from '../Common/Helpers';
import { styled, StyledProps } from '@glitz/react';
type RedirectType = {
  kexNavigate: any;
  target: string;
  url: string;
  onNavigated?: () => void;
  fullPageReload?: boolean;
};
type PropType = StyledProps & {
  onClick?: (x?: () => void) => void;
  callbackOnClick?: boolean;
  onNavigated?: () => void;
  children?: React.ReactNode;
  pageType?: any;
  href?: string;
  isMedia?: boolean;
  title?: string;
  fullPageReload?: boolean;
  target?: string;
};
const redirect = ({
  kexNavigate,
  target,
  url = '',
  onNavigated,
  fullPageReload,
}: RedirectType) => {
  if (url === '' || url === undefined) return;
  if (
    target !== '_blank' &&
    isInternalLink({ url }) &&
    !hasFileExtension({ url }) &&
    !fullPageReload
  ) {
    onNavigated ? kexNavigate(url).then(onNavigated()) : kexNavigate(url);
  } else if (isEmailLink({ url }) || fullPageReload) {
    window.location.href = url;
  } else {
    window.open(url, '_blank');
  }
};
const KexLink = ({
  onClick,
  callbackOnClick,
  onNavigated,
  children,
  href = '',
  isMedia,
  fullPageReload = false,
  compose,
  target,
  ...rest
}: PropType) => {
  const preventRefreshConditionally = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!fullPageReload || (href === '' && fullPageReload)) {
      e.preventDefault();
    }
  };
  const kexNavigate = useKexNavigate();
  return href &&
    typeof href === 'string' &&
    (href.substr(0, 4) === 'http' || target === '_blank') ? (
    <styled.A
      css={compose()}
      {...rest}
      href={href}
      {...(!fullPageReload && {
        target: '_blank',
      })}
    >
      {children}
    </styled.A>
  ) : (
    <styled.A
      css={compose()}
      {...rest}
      onClick={e => {
        e.persist();
        preventRefreshConditionally(e);
        const target = e.currentTarget.target;
        if (callbackOnClick) {
          onClick &&
            onClick(() =>
              redirect({
                kexNavigate,
                target,
                url: href ?? '',
                onNavigated,
                fullPageReload,
              })
            );
        } else {
          onClick && onClick();
          redirect({
            kexNavigate,
            target,
            url: href ?? '',
            onNavigated,
            fullPageReload,
          });
        }
      }}
      href={href}
    >
      {children}
    </styled.A>
  );
};
export default styled(KexLink);
