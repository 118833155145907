import React from 'react';
import loadable from '@loadable/component';

import KexPage from './Shared/KexPage/KexPage';
import { KexRouter } from './Shared/KexRouter/KexRouter';
import { HeaderProvider, useHeaderData } from './Shared/Models/Headers/Header';
import Footer from './Shared/Models/Footers/Footer/Footer';
import { KexLoadingCircle } from './Shared/Loading/KexLoadingCircle/KexLoadingCircle';
import KexReporterOverlay from './Shared/KexReporterOverlay/KexReporterOverlay';
import KexNotificationOverlay from './Shared/Notifications/BasicNotification';
import ErrorBoundary from './Shared/ErrorBoundary/ErrorBoundary';
import useCurrentPage from '../Features/Shared/Hooks/useCurrentPage';
import AppInitModel from './Shared/Models/AppInitModel.interface';
import PageModelBase from './Shared/Models/PageModelBase.interface';
import { AppSettingsProvider } from './Shared/AppSettingsProvider/AppSettingsProvider';
import { UserContextProvider } from './Shared/UserContextProvider/UserContextProvider';
import LeftNavigationLayout from './LeftNavigationLayout/LeftNavigationLayout';
import HeaderLink from './Shared/Models/Headers/HeaderLink.interface';
import { recToolPageType } from './IndexServer';

type PropType = {
  appInitData: AppInitModel;
};

const COMMERCE_LAYOUTS = ['CategoryPage', 'AssortmentPage'];
const ACCOUNT_LAYOUTS = ['AccountPage', 'OrderHistoryPage', 'OrderPage'];

const NotFoundPage = loadable(() =>
  import(/* webpackPrefetch: true */ './NotFoundPage/NotFoundPage')
);

const Content = ({ appInitData }: PropType) => {
  const { pageType, leftNavigationLinks } = useCurrentPage<PageModelBase>();
  const { commerceSubLinks, profileLinks } = useHeaderData();

  const renderLeftMenu = () => {
    return isCommercePage() || isAccountPage();
  };

  const isCommercePage = () => {
    return COMMERCE_LAYOUTS.includes(pageType);
  };

  const isAccountPage = () => {
    return ACCOUNT_LAYOUTS.includes(pageType);
  };

  const getLeftNavigationLinks = () => {
    let links: HeaderLink[];
    if (isCommercePage()) {
      links = commerceSubLinks || [];
    } else if (isAccountPage()) {
      links = profileLinks || [];
    } else {
      links = leftNavigationLinks || [];
    }
    return links;
  };

  return pageType === 'BlockPreviewPage' ? (
    <KexPage />
  ) : (
    <>
      {renderLeftMenu() ||
      (leftNavigationLinks && !!leftNavigationLinks.length) ? (
        <LeftNavigationLayout
          links={getLeftNavigationLinks()}
          isAccountPage={isAccountPage()}
        >
          <KexPage />
        </LeftNavigationLayout>
      ) : (
        <KexPage />
      )}
      {pageType !== 'CheckoutPage' &&
        pageType !== 'OrderConfirmationPage' &&
        pageType !== recToolPageType && (
          <Footer initData={appInitData.footer} />
        )}
      <KexNotificationOverlay />
    </>
  );
};

export function reloadDynamics(): void {
  if (window) {
    (window as any).MsCrmMkt = undefined;
    let script = (document as any).getElementById('dynamics-script');
    if (script) script.remove();
    script = document.createElement('script');
    script.id = 'dynamics-script';
    script.src =
      'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007';
    script.async = true;
    document.body.appendChild(script);
  }
}

function App({ appInitData }: PropType) {
  // Because of a bug in chrome, we have to wait before scrolling to anchor
  if (typeof window !== 'undefined') {
    if (window?.location?.hash) {
      if (window.location.hash.length > 0) {
        setTimeout(function() {
          var hash = window.location.hash;
          window.location.hash = '';
          window.location.hash = hash;
        }, 500);
      }
    }
  }

  return (
    <AppSettingsProvider data={appInitData.appSettings}>
      <UserContextProvider data={appInitData.userState}>
        <KexReporterOverlay />
        <ErrorBoundary FallbackComponent={() => <NotFoundPage />}>
          <KexLoadingCircle>
            <KexRouter appInitData={appInitData} initUrl={appInitData.initUrl}>
              <HeaderProvider initData={appInitData.header}>
                <Content appInitData={appInitData} />
              </HeaderProvider>
            </KexRouter>
          </KexLoadingCircle>
        </ErrorBoundary>
      </UserContextProvider>
    </AppSettingsProvider>
  );
}

export default App;
