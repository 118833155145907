export const zIndex = {
  PageElement: 1,
  Notification: 9,
  Header: 10,
  HeaderMobile: 9,
  MobileOverlay: 8,
  Flyout: 10,
  MegaMenu: 9,
  DropDown: 10,
  TopBar: 11,
  FlyoutLower: 5,
  Cart: 10,
  CartMobile: 9,
  MobileMenu: 8,
  Modal: 20,
  Overlay: 980,
  pageLoader: 990,
  errorModal: 1000,
  ModalTopBar: 1010,
  contentOverlayWithHeader: 4,
};
