import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ChevronIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path d="M14.5040322,6.13192798 C14.9835496,5.85791801 15.5944043,6.02451474 15.8684143,6.50403218 C16.1228521,6.94929838 15.997379,7.50780943 15.5942277,7.80471267 L15.4963101,7.86841426 L8.49631006,11.8684143 C8.22730647,12.0221306 7.90472455,12.0413451 7.62232638,11.9260579 L7.50403218,11.8684143 L0.504032182,7.86841426 C0.024514735,7.59440429 -0.142081992,6.98354963 0.131927978,6.50403218 C0.386365807,6.05876598 0.931246255,5.88331408 1.39171668,6.079909 L1.49631006,6.13192798 L8.00017112,9.84717112 L14.5040322,6.13192798 Z"></path>
    </styled.Svg>
  );
}

export default styled(ChevronIcon);
