import { useCallback, useLayoutEffect, useState } from 'react';
import useEventListener from './useEventListener';

const useSetHeaderHeight = (): [(ref: HTMLElement | null) => void, number] => {
  const [ref, setRef] = useState<HTMLElement>();
  const [height, setHeight] = useState(0);

  const handleSize = useCallback(() => {
    const bounding = ref?.getBoundingClientRect();

    setHeight(bounding?.bottom || 0);
  }, [ref]);

  useEventListener('transitionend', handleSize, ref);

  useLayoutEffect(() => {
    handleSize();
  }, [handleSize]);

  const setRefCallback = useCallback((ref: HTMLElement | null) => {
    ref && setRef(ref);
  }, []);

  return [setRefCallback, height];
};

export default useSetHeaderHeight;
