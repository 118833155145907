import React, { useCallback, useEffect, useRef } from 'react';
import { styled, theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import { TopClose } from './Button/Close';
import usePreventBackgroundScroll from '../../Shared/Hooks/usePreventBackgroundScroll';
import { StyledProps } from '@glitz/react';
import { Button } from '../Button/Button';
import ArrowButton from '../Button/ArrowButton';
import { LinkType } from '../../Enums/LinkType.enum';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';

type PropType = StyledProps & {
  children: React.ReactNode;
  toggle: (id?: number) => void;
  leftArrowClick?: () => void;
  rightArrowClick?: () => void;
  overlay: boolean;
  isUserAction?: boolean;
  isNavigationModal?: boolean;
  hasCloseButton?: boolean;
  isImageContainer?: boolean;
  imageNumber?: number;
  isNotification?: boolean;
  isClosedOnOutsideClick?: boolean;
  isMaxWidth?: boolean;
};

function Modal({
  children,
  toggle,
  overlay,
  isUserAction = false,
  isNavigationModal = false,
  hasCloseButton = false,
  isImageContainer = false,
  isNotification = false,
  isClosedOnOutsideClick = true,
  leftArrowClick,
  rightArrowClick,
  imageNumber = 0,
  isMaxWidth = true,
  compose,
}: PropType) {
  const {
    translations: { 'common/navigationModalButton': navigationButtonText },
  } = useAppSettingsData();
  const modalRef = useRef<HTMLDivElement>(null);
  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        toggle();
      }
    },
    [toggle]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  useOutsideClick(modalRef, () => {
    if (isClosedOnOutsideClick && overlay) {
      toggle();
    }
  });

  usePreventBackgroundScroll();
  const modalStyle = {
    ...(overlay && {
      ...activeStyle,
    }),
    ...(overlay &&
      isUserAction && {
        ...activeStyle,
        ...userActionContainerStyle,
      }),
    ...(isUserAction &&
      !overlay && {
        ...userActionContainerStyle,
      }),
    ...(isNavigationModal && {
      ...navigationContainerStyle,
    }),
  };

  const userActionStyle = isMaxWidth
    ? userActionDivStyle
    : userActionDivNoMaxWidthStyle;

  const modalDivStyle = {
    ...(isUserAction && {
      ...userActionStyle,
    }),
    ...(isNavigationModal && {
      ...navigationDivStyle,
    }),
    ...(isNotification && {
      ...notificationDivStyle,
    }),
  };

  return (
    <ModalContainer css={modalStyle}>
      <ModalDiv
        css={compose ? compose(modalDivStyle) : modalDivStyle}
        ref={modalRef}
      >
        {!isNavigationModal && hasCloseButton && (
          <TopClose closeLabel={''} toggle={toggle} />
        )}
        {children}
        {isNavigationModal && (
          <ViewProductsButton onClick={toggle}>
            {navigationButtonText}
          </ViewProductsButton>
        )}
        {isImageContainer && imageNumber > 1 && (
          <>
            <LeftArrow
              type={LinkType.Left}
              onClick={leftArrowClick && leftArrowClick}
            />
            <RightArrow
              type={LinkType.Right}
              onClick={rightArrowClick && rightArrowClick}
            />
          </>
        )}
      </ModalDiv>
    </ModalContainer>
  );
}

const navigationContainerStyle: StyleOrStyleArray = {
  padding: { x: theme.spacing(4) },
};

const navigationDivStyle: StyleOrStyleArray = {
  height: '100%',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  padding: { xy: theme.none },
  flexDirection: 'column',
  overflow: 'auto',
};

const activeStyle: StyleOrStyleArray = {
  ...pseudo(':before', {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    backgroundColor: theme.black,
    opacity: '0.5',
    content: '""',
  }),
};

const ModalContainer = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: theme.zIndex.Modal,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: {
    xy: theme.spacing(4),
    top: theme.spacing(8),
    right: theme.spacing(8),
  },

  ...media(theme.mediaQuery.mediaMinLarge, {
    height: '100vh',
    padding: {
      xy: theme.spacing(16),
    },
    width: '100%',
  }),
});

const LeftArrow = styled(ArrowButton, {
  left: `-${theme.spacing(6)}px`,
  ...media(theme.mediaQuery.mediaMinLarge, {
    left: theme.spacing(12),
  }),
});
const RightArrow = styled(ArrowButton, {
  right: `-${theme.spacing(6)}px`,
  ...media(theme.mediaQuery.mediaMinLarge, {
    right: theme.spacing(12),
  }),
});

const ModalDiv = styled.div({
  backgroundColor: theme.white,
  height: 'auto',
  width: '100%',
  padding: {
    xy: theme.spacing(4),
  },

  ...media(theme.mediaQuery.mediaMinMedium, {
    maxHeight: theme.productImageMaxHeightMedium,
    width: '100%',
    height: '100%',
    padding: {
      xy: theme.spacing(4),
    },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxHeight: 'unset',
    padding: { xy: 0 },
  }),
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const userActionDivStyle: StyleOrStyleArray = {
  width: '100%',
  ...media(theme.mediaQuery.mediaMinMedium, {
    height: 'auto',
    width: '100%',
    maxWidth: theme.modalWidthUserAction,
    padding: { x: theme.none },
    margin: { x: theme.none },
  }),
};

const userActionDivNoMaxWidthStyle: StyleOrStyleArray = {
  width: '100%',
  ...media(theme.mediaQuery.mediaMinMedium, {
    height: 'auto',
    width: '100%',
    padding: { x: theme.none },
    margin: { x: theme.none },
  }),
};

const notificationDivStyle: StyleOrStyleArray = {
  width: '100%',
  maxWidth: '900px !important',
  backgroundColor: theme.lightAqua,
  ...media(theme.mediaQuery.mediaMinMedium, {
    height: 'auto',
    width: '100%',
    maxWidth: 'unset',
    padding: { x: theme.none },
    margin: { x: theme.none },
  }),
};
const userActionContainerStyle: StyleOrStyleArray = {
  ...media(theme.mediaQuery.mediaMinMedium, {
    alignItems: 'start',
  }),
};

const ViewProductsButton = styled(Button, {
  margin: { x: 'auto', bottom: theme.spacing(8) },
});

export default styled(Modal);
