import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function CloseIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path d="M4.61289944,3.20970461 L4.70710678,3.29289322 L8,6.585 L11.2928932,3.29289322 L11.3871006,3.20970461 C11.7793918,2.90467972 12.3466228,2.93240926 12.7071068,3.29289322 C13.0976311,3.68341751 13.0976311,4.31658249 12.7071068,4.70710678 L12.7071068,4.70710678 L9.415,8 L12.7071068,11.2928932 C13.0976311,11.6834175 13.0976311,12.3165825 12.7071068,12.7071068 C12.3466228,13.0675907 11.7793918,13.0953203 11.3871006,12.7902954 L11.2928932,12.7071068 L8,9.415 L4.70710678,12.7071068 L4.61289944,12.7902954 C4.22060824,13.0953203 3.65337718,13.0675907 3.29289322,12.7071068 C2.90236893,12.3165825 2.90236893,11.6834175 3.29289322,11.2928932 L3.29289322,11.2928932 L6.585,8 L3.29289322,4.70710678 C2.90236893,4.31658249 2.90236893,3.68341751 3.29289322,3.29289322 C3.65337718,2.93240926 4.22060824,2.90467972 4.61289944,3.20970461 Z"></path>
    </styled.Svg>
  );
}

export default styled(CloseIcon);
