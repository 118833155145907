import React from 'react';
import { styled, theme } from '../../Theme';
import ErrorBlockModel from './Models/ErrorBlockModel.interface';
import Link from '../../../Features/Shared/Models/Link.interface';
import XhtmlComponent from './../../../Features/Shared/XhtmlComponent/XhtmlComponent';
import { applyEditModeAttr } from '../../Shared/Common/Helpers';
import IconLink from '../../Shared/IconLink/IconLink';
import { LinkType } from '../../Enums/LinkType.enum';
import { media } from '@glitz/core';

type PropType = {
  content: ErrorBlockModel;
};

function ErrorBlock({
  content: { bodyText, inEditMode, links, title, image },
}: PropType) {
  return (
    <Root>
      {image && (
        <Image src={image.src} alt={image.src} title={image.title}></Image>
      )}
      <Container>
        {title && (
          <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
            {title}
          </Heading>
        )}
        <BodyTextContainer>
          <XhtmlComponent
            {...applyEditModeAttr(inEditMode && 'BodyText')}
            content={bodyText}
            className={'error-text'}
          ></XhtmlComponent>
        </BodyTextContainer>
        {links &&
          Array.isArray(links) &&
          links.map((link: Link, index: number) => (
            <LinkContainer key={`${index}${link.href}`}>
              <IconLink
                href={link.href}
                {...applyEditModeAttr(inEditMode && 'Link')}
                type={LinkType.Arrow}
                fullPageReload={link.fullPageReload}
              >
                {link.text}
              </IconLink>
            </LinkContainer>
          ))}
      </Container>
    </Root>
  );
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: { x: theme.spacing(10), y: theme.none },
  margin: { xy: 'auto' },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { x: theme.none, y: theme.none },
  }),
});

const LinkContainer = styled.div({
  width: 'fit-content',
  color: theme.blueDark,
  marginBottom: theme.spacing(2),
  height: theme.spacing(6),
});

const Heading = styled.h1({
  font: {
    size: theme.iota,
    weight: theme.fontWeight.bold,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const BodyTextContainer = styled.div({
  color: theme.black,
  marginBottom: theme.spacing(4),
  fontSize: theme.gamma,
});

const Root = styled.div({
  margin: { x: 'auto' },
  padding: { y: theme.spacing(16) },
  width: '100%',
  height: '100%',
  backgroundColor: theme.lightAqua,
  zIndex: theme.zIndex.errorModal,
  overflowY: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const Image = styled.img({
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: theme.spacing(8),
  maxWidth: '80%',
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '100%',
  }),
});

export default ErrorBlock;
