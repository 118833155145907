import React, { useState, useEffect } from 'react';
import { EventDispatcher, ON_KEX_ERROR } from '../Common/EventDispatcher';
import KexReporterModal from './KexReporterModal';

function KexReporterOverlay() {
  const [errorCount, setErrorCount] = useState(0);

  const onError = (isActive: boolean) => {
    setErrorCount(1);
    document.body.style.overflow = isActive ? 'hidden' : 'unset'; //disable scroll on page behind modal.
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_KEX_ERROR, () => onError(true));

    return () => {
      EventDispatcher.unsubscribe(ON_KEX_ERROR, () => onError(false));
    };
  });

  return <>{errorCount !== 0 && <KexReporterModal />}</>;
}

export default KexReporterOverlay;
