import React from 'react';
import { styled, theme } from '../../Theme';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import { SmallButton } from '../Button/Button';
import useMedia from '../Hooks/useMedia';
import { SignOut } from '../../AccountPage/Account';
import { useUserStateData } from '../UserContextProvider/UserContextProvider';

function AccountOverview() {
  const {
    translations: {
      'account/signOut': signOut,
      'account/organisation': organisationText,
      'account/customerNumber': customerNumberText,
    },
    languageRoute,
    staticPages: { accountPage },
  } = useAppSettingsData();
  const { companyNumber, companyName } = useUserStateData();

  const isMobile = useMedia(theme.mediaQuery.mediaMaxMedium);

  return (
    <>
      {!isMobile && (
        <Wrapper>
          <InfoGridBox>
            {companyName && companyNumber && (
              <InfoWrapper>
                <InfoField>
                  {organisationText} <InfoValue>{companyName}</InfoValue>
                </InfoField>
                <InfoField>
                  {customerNumberText} <InfoValue>{companyNumber}</InfoValue>
                </InfoField>
              </InfoWrapper>
            )}
            <SignOutButton
              onClick={() => SignOut(accountPage, languageRoute)}
              isActive={false}
            >
              {signOut}
            </SignOutButton>
          </InfoGridBox>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div({
  paddingTop: theme.spacing(8),
  border: {
    top: {
      color: theme.thinGray,
      style: 'solid',
      width: theme.tiny,
    },
  },
});

const GridBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const InfoField = styled.p({
  fontSize: theme.beta,
  fontWeight: theme.fontWeight.bold,
  color: theme.black,
  margin: { y: theme.spacing(1) },
  lineHeight: theme.lineHeight.moreSnug,
});

const InfoValue = styled.span({
  fontWeight: theme.fontWeight.normal,
});

const InfoWrapper = styled.div({
  paddingBottom: theme.spacing(8),
});

const SignOutButton = styled(SmallButton, {
  font: { weight: theme.fontWeight.bold },
  fontSize: theme.zeta,
  letterSpacing: theme.letterSpacing.medium,
  width: '100%',
  borderRadius: 'none',
  marginLeft: 'auto',
  minWidth: theme.spacing(23),
  minHeight: theme.spacing(11),
});

const InfoGridBox = styled(GridBox);

export default AccountOverview;
