import React from 'react';
import { styled, theme } from '../../../../Theme';
import ContentContainer from '../../../ContentContainer/ContentContainer';
import Grid from '../../../Grid/Grid';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import FooterList from './FooterList/FooterList';
import IFooter from './Footer.interface';
import useQueryFooter from './useQueryFooter';
import { media } from '@glitz/core';
import IconBlock from '../../../../Blocks/IconBlock/IconBlock';

type PropTypes = {
  initData: IFooter;
};

function Footer({ initData }: PropTypes) {
  const [
    {
      footerText,
      footerLeftColumnLinks,
      footerCenterColumnLinks,
      footerRightColumnLinks,
      footerLeftHeader,
      footerCenterHeader,
      footerRightHeader,
      socialMediaLinks,
    },
  ] = useQueryFooter(initData);

  return (
    <FooterElm>
      <ContentContainer>
        <Grid>
          <div>
            <CompanyInfo text={footerText} />
          </div>
          <div>
            <FooterList
              header={footerLeftHeader}
              links={footerLeftColumnLinks}
            />
          </div>
          <div>
            <FooterList
              header={footerCenterHeader}
              links={footerCenterColumnLinks}
            />
          </div>
          <div>
            <FooterList
              header={footerRightHeader}
              links={footerRightColumnLinks}
            />
          </div>
          {socialMediaLinks && (
            <SocialMediaList>
              {socialMediaLinks.map(i => (
                <IconBlock content={i} blockType={''} />
              ))}
            </SocialMediaList>
          )}
        </Grid>
      </ContentContainer>
    </FooterElm>
  );
}

const FooterElm = styled.footer({
  backgroundColor: theme.thinAqua,
  position: 'relative',
  color: theme.black,
  height: 'auto',
  padding: {
    x: theme.none,
    y: theme.spacing(16),
  },
  ...media(theme.mediaQuery.mediaMinMedium, {
    marginBottom: theme.none,
  }),
});
const SocialMediaList = styled.div({
  display: 'flex',
});
export default Footer;
