import React, { useRef, useState } from 'react';
import { styled, theme } from '../../Theme';
import { StyledProps } from '@glitz/react';
import { StyleOrStyleArray } from '@glitz/type';
import { media, pseudo } from '@glitz/core';
import ChevronIcon from './../Icons/ChevronIcon';
import { LoadingCircle } from '../Icons';

type ButtonType = StyledProps & {
  children?: JSX.Element | string | React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  text?: string;
};

type IconButtonType = StyledProps & {
  onClick?: () => void;
  isActive?: boolean;
  icon: JSX.Element;
  disabled?: boolean;
};

type FeedbackType = StyledProps & {
  children: JSX.Element | string;
  action: () => Promise<any>;
  icon?: JSX.Element;
  disabled?: boolean;
};

export function Button({
  isActive,
  onClick,
  children,
  icon,
  compose,
  disabled,
}: ButtonType) {
  return (
    <Base
      onClick={onClick}
      css={compose(isActive ? activeStyle : {})}
      disabled={disabled}
    >
      {icon}
      <ChildText>{children}</ChildText>
    </Base>
  );
}

export function IconButton({
  isActive,
  onClick,
  icon,
  compose,
  disabled,
}: IconButtonType) {
  return (
    <IconBase
      onClick={onClick}
      css={compose(isActive ? activeStyle : {})}
      disabled={disabled}
    >
      {icon}
    </IconBase>
  );
}

export function SmallButton({
  isActive,
  onClick,
  children,
  icon,
  compose,
  disabled,
  text,
}: ButtonType) {
  return (
    <SmallBase
      onClick={onClick}
      css={compose(isActive ? activeStyle : inactiveStyle)}
      disabled={disabled}
    >
      {icon}
      <TextWapper>{children ? children : text} </TextWapper>
    </SmallBase>
  );
}

export function ChipsButton({
  isActive,
  onClick,
  children,
  compose,
}: ButtonType) {
  return (
    <ChipButton
      onClick={onClick}
      css={compose(isActive ? activeChipStyle : {})}
    >
      <ChipsTextWapper>{children}</ChipsTextWapper>
    </ChipButton>
  );
}

export function RegionButton({
  isActive,
  onClick,
  children,
  icon,
  compose,
  disabled,
}: ButtonType) {
  return (
    <SmallBase
      onClick={onClick}
      css={compose(isActive ? activeStyle : inactiveStyle)}
      disabled={disabled}
    >
      {icon}
      <TextWapper
        css={isActive ? { color: theme.white } : { color: theme.blueDark }}
      >
        {children}
      </TextWapper>
    </SmallBase>
  );
}

export function VariationButton({
  isActive,
  onClick,
  children,
  compose,
  disabled,
  icon,
}: ButtonType) {
  return (
    <VariationBase
      onClick={onClick}
      css={compose(isActive ? VariationActiveStyle : {})}
      disabled={disabled}
    >
      {icon}
      <ChildText>{children}</ChildText>
    </VariationBase>
  );
}

export function FeedbackButton({
  action,
  children,
  compose,
  disabled,
}: FeedbackType) {
  const [isLoading, setLoading] = useState(false);
  const buttonRef = useRef(null);

  function resolvePromise() {
    setLoading(true);
    action().then(() => {
      if (!buttonRef?.current) {
        return;
      }
      setLoading(false);
    });
  }

  return (
    <FeedbackBase
      onClick={resolvePromise}
      css={compose(isLoading ? FeedbackLoadingStyle : {})}
      disabled={disabled}
      ref={buttonRef}
    >
      <>
        {isLoading && <StyledLoadingCircle isLoading={isLoading} />}
        <styled.Div css={isLoading ? { opacity: 0 } : {}}>
          {children}
        </styled.Div>
      </>
    </FeedbackBase>
  );
}

export function LinkButton({
  isActive,
  onClick,
  children,
  icon,
  compose,
  disabled,
}: ButtonType) {
  return (
    <LinkBase
      onClick={onClick}
      css={compose(isActive ? activeStyle : {})}
      disabled={disabled}
    >
      {icon}
      {children}
    </LinkBase>
  );
}

export function DropDownButton({ onClick, children, compose }: ButtonType) {
  return (
    <DropDownButtonBase onClick={onClick} css={compose()}>
      {children}
      <ChevronIcon
        css={{ width: theme.spacing(4), height: theme.spacing(4) }}
      />
    </DropDownButtonBase>
  );
}

const activeStyle: StyleOrStyleArray = {
  backgroundColor: theme.blueDark,
  color: theme.white,
  ...pseudo(':hover', {
    backgroundColor: theme.primary,
    border: { xy: { color: theme.primary } },
  }),
};

const inactiveStyle: StyleOrStyleArray = {
  backgroundColor: theme.white,
  color: theme.blueDark,
  border: { xy: { color: theme.blueDark, width: theme.spacing(0.5) } },
  ...pseudo(':hover', {
    backgroundColor: theme.white,
    border: { xy: { color: theme.primary } },
  }),
};

const TextWapper = styled.div({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bold,
  letterSpacing: theme.letterSpacing.moreWide,
  lineHeight: theme.lineHeight.snug,
});

const Base = styled.button({
  padding: { y: theme.spacing(4), x: theme.spacing(8) },
  borderRadius: theme.none,
  color: theme.white,
  height: 'auto',
  backgroundColor: theme.blueDark,
  transition: {
    property: 'opacity',
    duration: theme.timings.oneHalf,
    timingFunction: 'linear',
  },
  ':active': {
    outline: {
      width: 0,
    },
    transform: 'scale(.96)',
  },
  ':focus': {
    outline: {
      width: 0,
    },
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.7,
  },
});

const SmallBase = styled(Base, {
  padding: { y: theme.spacing(4), x: theme.spacing(8) },
  lineHeight: theme.lineHeight.snug,
});

const VariationActiveStyle: StyleOrStyleArray = {
  backgroundColor: theme.lightGray,
};

const VariationBase = styled.button({
  padding: { xy: theme.spacing(4) },
  lineHeight: theme.lineHeight.snug,
  backgroundColor: theme.white,
  display: 'flex',
  flexDirection: 'row',
  fontSize: theme.alpha,
  ':hover': {
    textDecoration: 'underline',
  },
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.lightGray,
    },
  },
});

const IconBase = styled(Base, {
  padding: { xy: theme.spacing(5) },
  backgroundColor: theme.blueDark,
  borderRadius: theme.none,
});

const FeedbackBase = styled(Base, {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.blueDark,
});

const FeedbackLoadingStyle: StyleOrStyleArray = {
  backgroundColor: theme.loading,
};

const LinkBase = styled.button({
  padding: { x: theme.spacing(4), y: '8px' },
  margin: { right: theme.spacing(3) },
  backgroundColor: theme.primary,
  color: theme.white,
  font: { size: '16px', weight: theme.fontWeight.lighter },
  letterSpacing: theme.letterSpacing.wide,
  lineHeight: 1.75,
  ...media(theme.mediaQuery.mediaMaxSmall, {
    font: { size: theme.beta },
  }),
});

const DropDownButtonBase = styled.button({
  padding: { y: theme.spacing(3), x: theme.spacing(4) },
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: {
    xy: { width: theme.tiny, color: theme.black },
  },
  fontSize: theme.gamma,
});

const ChildText = styled.div({
  marginLeft: theme.spacing(2),
  alignSelf: 'center',
  fontWeight: theme.fontWeight.bold,
});

const StyledLoadingCircle = styled(LoadingCircle, {
  height: theme.spacing(4),
  position: 'absolute',
  width: theme.spacing(4),
  margin: { xy: 'auto' },
});

const ChipButton = styled(SmallBase, {
  backgroundColor: theme.white,
  color: theme.primary,
  padding: { y: theme.spacing(2), x: theme.spacing(4) },
  borderRadius: theme.spacing(5),
  border: {
    xy: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.blueDark,
    },
  },
});

const ChipsTextWapper = styled.div({
  font: {
    size: theme.beta,
    weight: theme.fontWeight.bold,
  },
  letterSpacing: theme.letterSpacing.moreWide,
  lineHeight: theme.lineHeight.snug,
});

const activeChipStyle: StyleOrStyleArray = {
  backgroundColor: theme.blueDark,
  color: theme.white,
  ...pseudo(':hover', {
    backgroundColor: theme.primary,
    border: { xy: { color: theme.primary } },
  }),
};
