import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { useKexLoadingCircle } from '../../../Loading/KexLoadingCircle/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../../Configs/EnvConfig';
import FetchFooter from './FetchFooter';
import IFooter from './Footer.interface';
import { useAppSettingsData } from '../../../AppSettingsProvider/AppSettingsProvider';

let hasMounted = false;

export default function(initialData: IFooter): [IFooter] {
  const { languageRoute } = useAppSettingsData();
  const [data, setData] = useState<IFooter>(initialData);
  const kexLoadingCircleDispatch = useKexLoadingCircle();
  const queryData = useSWR<IFooter>(
    `/App/GetStandardFooter?culture=${languageRoute}`,
    FetchFooter,
    {
      initialData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch(!queryData.data ? 'add' : 'remove');
  }, [kexLoadingCircleDispatch, queryData.data]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}
