import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ArrowRightLinkIcon from '../Icons/ArrowRightLinkIcon';
import ChevronIcon from '../Icons/ChevronIcon';
import DownloadIcon from '../Icons/DownloadIcon';
import KexLink from '../KexLink/KexLink';

import { styled, theme } from '../../Theme';
import { LinkType } from '../../Enums/LinkType.enum';
import { StyledProps } from '@glitz/react';
import { media, pseudo } from '@glitz/core';

type PropTypes = StyledProps & {
  whiteText?: boolean;
  underline?: boolean;
  onClick?: any;
  href?: string;
  children: string | undefined;
  focused?: any;
  type: string;
  hasUrl?: boolean;
  fullPageReload?: boolean;
};

function IconLink({
  whiteText = false,
  onClick,
  href,
  children,
  focused,
  type,
  hasUrl,
  fullPageReload = false,
  compose,
  ...rest
}: PropTypes) {
  const [linkIsHovered, setLinkIsHovered] = useState(false);

  return (
    <StyledLinkWrapper
      onMouseOver={() => setLinkIsHovered(true)}
      onMouseLeave={() => setLinkIsHovered(false)}
      css={compose()}
    >
      <StyledKexLink
        css={compose()}
        {...rest}
        onClick={onClick}
        href={href}
        fullPageReload={fullPageReload}
      >
        <StyledLinkSpan
          css={LinkType.ArrowOnHover ? { width: '100%' } : {}}
          data-whitetext={whiteText}
        >
          {children}
        </StyledLinkSpan>
        <IconWrapper
          data-linkhovered={linkIsHovered}
          data-whitetext={whiteText}
        >
          {type === LinkType.Arrow ? (
            <StyledArrowRightIcon />
          ) : (
            type === LinkType.Document && <StyledDownloadIcon />
          )}
          {type === LinkType.ArrowOnHover &&
            linkIsHovered &&
            (hasUrl ? (
              <StyledArrowRightIcon css={{ marginRight: theme.spacing(8) }} />
            ) : (
              <ChevronDiv>
                <StyledChevronIcon
                  css={{
                    marginRight: theme.spacing(4),
                    transform: 'scale(1,-1)',
                  }}
                />
                <StyledChevronIcon css={{ marginRight: theme.spacing(8) }} />
              </ChevronDiv>
            ))}
        </IconWrapper>
      </StyledKexLink>
    </StyledLinkWrapper>
  );
}

const StyledKexLink = styled(KexLink, {
  color: theme.blueDark,
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bold,
  ':hover': {
    textDecoration: 'underline',
  },
  display: 'flex',
  alignItems: 'center',
});

const IconWrapper = styled.div({
  alignSelf: 'center',
  ...pseudo([':nth-child(n)[data-linkhovered="true"]'], {
    ...media(theme.mediaQuery.mediaMinLarge, {
      animationDuration: theme.timings.oneThird,
      animationTimingFunction: 'cubic-bezier(0.47, 0.01, 0.51, 1)',
      animationName: {
        '0%': {
          transform: 'translateX(0)',
        },

        '50%': {
          transform: 'translateX(-10px)',
        },

        '100%': {
          transform: 'translateX(0px)',
        },
      },
    }),
  }),
  ...pseudo([':nth-child(n)[data-whitetext="true"]'], { fill: theme.white }),
});

const StyledArrowRightIcon = styled(ArrowRightLinkIcon, {
  fill: theme.blueDark,
  width: theme.spacing(4),
  height: theme.spacing(3.5),
});
const StyledChevronIcon = styled(ChevronIcon, {
  fill: theme.blueDark,
  width: theme.spacing(4),
  height: theme.spacing(3.5),
  marginTop: theme.spacing(-1),
});
const ChevronDiv = styled.div({});

const StyledDownloadIcon = styled(DownloadIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(2),
});

const StyledLinkWrapper = styled.div({
  width: 'fit-content',
});

const StyledLinkSpan = styled.div({
  marginRight: theme.spacing(3),
  ...pseudo([':nth-child(n)[data-whitetext="true"]'], { color: theme.white }),
});

export default styled(IconLink);
